import Navbar from "./navbar";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import SideNavbar from "./side_navbar";
import { useStateValue } from "../state_manager/contextApi";
import { getTheme } from "../functions/general";
import { SET_DARK_THEME, SET_LIGHT_THEME } from "../state_manager/constants";

import "react-toastify/dist/ReactToastify.css";
import "../styles/nprogress.css";
import ExpiredSessionModal from "./ExpiredSessionModal";
import { useSelector } from "react-redux";

const Layout = () => {
  const { sessionExpired } = useSelector((state: any) => state.admin);
  const {
    state: { theme },
    dispatch,
  } = useStateValue();

  useEffect(() => {
    const localTheme = getTheme();
    localTheme === "dark" && dispatch({ type: SET_DARK_THEME });
    localTheme === "light" && dispatch({ type: SET_LIGHT_THEME });
  }, [dispatch]);

  useEffect(() => {
    if (theme === "dark") {
      document.querySelector("body")?.classList.add("dark");
    } else {
      document.querySelector("body")?.classList.remove("dark");
    }
  }, [theme]);

  return (
    <div className="layout">
      {sessionExpired && <ExpiredSessionModal />}
      <Navbar />
      <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
        <SideNavbar />

        {/* This is where the other components appears */}

        <div
          className="layout_body"
          style={{ overflowX: "hidden", height: "auto", minHeight: "100vh" }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
