import React from "react";
import { Fragment, SetStateAction, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {} from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MdKeyboardArrowDown } from "react-icons/md";
import { CiCalendar } from "react-icons/ci";

import { useDispatch, useSelector } from "react-redux";
import { requestDisplayFarmers } from "../../../../redux/actions/farmers";
import { addCycleActions } from "../../../../redux/slice/cycles/addCycleSlice";
import { STORE_ACTION_TYPES } from "../../../../redux/types";
import { formatDate } from "../../../../functions/formatDate";
import { getFarmTypes } from "../../../../functions/general";
import { FarmType } from "../../../../db/interfaces";

interface Farmer {
  id: number;
  name: string;
}

export const cropTypes = ["Maize", "Rice", "Beans", "Soyabeans", "Groundnuts"];
export const cropVarieties = [
  "Maize",
  "Rice",
  "Beans",
  "Soyabeans",
  "Groundnuts",
];

export default function Farmer() {
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [cropType, setCropType] = useState("");
  const [cropVariety, setCropVariety] = useState("");
  const [farmTypes, setFarmTypes] = useState<FarmType[]>([]);
  const [farmers, setFarmers] = useState<Farmer[]>([]);

  useEffect(() => {
    let fetchedFarmTypes = getFarmTypes();
    fetchedFarmTypes.then((res) => setFarmTypes(res));
  }, []);
  

  // const { farmers } = useSelector((state: any) => state.farmers);

  useEffect(() => {
    async function fetchFarmers() {
      const data = await requestDisplayFarmers();
      setFarmers(data); // store fetched data in state
    }

    fetchFarmers();
  }, []); // empty dependency array means this effect runs once on mount


  const [selected, setSelected] = useState<any>("--Select Farmer--");

  const { user } = useSelector((state: any) => state.admin);

  const dispatch = useDispatch();

  useEffect(() => {

  requestDisplayFarmers();

  }, []);

 

  const filteredFarmers = query === ''
  ? farmers
  : farmers.filter(farmer =>
      farmer.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));

  // if (failedMsg) console.log(failedMsg);

  const handleFarmerChange = (farmer: Farmer) => {
    setSelected(farmer);

    dispatch(
      addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.UPDATE_INFO]({
        farmer_id: farmer.id,
        name: farmer.name,
      })
    );
  };

  const handleCropChange = (crop: any) => {
    setCropType(crop.name);
    dispatch(
      addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.UPDATE_INFO]({
        crop_type: crop.name,
      })
    );
  };

  const handleVarietyChange = (e: any) => {
    setCropVariety(e.target.value);
    dispatch(
      addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.UPDATE_INFO]({
        variety: e.target.value,
      })
    );
  };


  const handleStartDate = (date: any) => {
    const formattedDate = formatDate(date);

    setStartDate(formattedDate);

    dispatch(
      addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.UPDATE_INFO]({
        start_date: formattedDate,
      })
    );
  };

  const handleEndDate = (date: any) => {
    const formattedDate = formatDate(date);

    setEndDate(formattedDate);

    dispatch(
      addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.UPDATE_INFO]({
        end_date: formattedDate,
      })
    );
  };

  return (
    <>
      <form className="py-5 w-full mt-7">
        <div className="w-full">
          <label className="text-xl leading-7 text-[#C4C4C4]">
            Farmer Name
          </label>
          <Combobox value={selected} onChange={handleFarmerChange}>
            <div className="relative mt-2">
              <div
                className="relative w-full h-[3.625rem] cursor-default overflow-hidden rounded-[10px] bg-white text-left
                 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 
                focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm
                flex justify-between items-center border border-[#C4C4C4]
                "
              >
                <Combobox.Input
                  className="w-full flex-1 border-none text-xl leading-7 bg-white px-4 text-[#2A2A2A] focus:ring-0"
                  displayValue={(farmer: Farmer) => farmer?.name}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="flex w-[unset] items-center pr-2 mt-0 p-0">
                  <MdKeyboardArrowDown
                    className="h-6 w-6 text-[#C4C4C4]"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md
                   bg-white py-1 text-base shadow-lg ring-1
                   ring-black/5 focus:outline-none sm:text-sm"
                >
                  {filteredFarmers?.length === 0 && query !== "" ? (
                    <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filteredFarmers?.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }: any) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? "bg-teal-600 text-white" : "text-gray-900"
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }: any) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {person.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>

        <div className="flex gap-10 items-center mt-5">
          <div className="w-full">
            <label className="text-xl leading-7 text-[#C4C4C4]">
              Crop Type
            </label>
            <Combobox value={cropType} onChange={handleCropChange}>
              <div className="relative mt-2">
                <div
                  className="relative w-full h-[3.625rem] cursor-default overflow-hidden rounded-[10px] bg-white text-left
                 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 
                focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm
                flex justify-between items-center border border-[#C4C4C4]
                "
                >
                  <Combobox.Input
                    className="w-full flex-1 border-none text-xl leading-7 bg-white px-4 text-[#2A2A2A] focus:ring-0"
                    displayValue={(crop: any) => crop}
                    onChange={(event: any) => setCropType(event.target.value)}
                  />
                  <Combobox.Button className="flex w-[unset] items-center pr-2 mt-0 p-0">
                    <MdKeyboardArrowDown
                      className="h-6 w-6 text-[#C4C4C4]"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery("")}
                >
                  <Combobox.Options
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md
                   bg-white py-1 text-base shadow-lg ring-1
                   ring-black/5 focus:outline-none sm:text-sm"
                  >
                    {farmTypes?.length === 0 ? (
                      <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      farmTypes?.map((crop: any) => (
                        <Combobox.Option
                          key={crop.name}
                          className={({ active }: any) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-teal-600 text-white"
                                : "text-gray-900"
                            }`
                          }
                          value={crop}
                        >
                          {({ selected, active }: any) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {crop.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "text-white" : "text-teal-600"
                                  }`}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>

          <div className="w-full">
            <label className="text-xl leading-7 text-[#C4C4C4]">
              Crop Variety
            </label>
            <input
              type="text"
              value={cropVariety}
              onChange={handleVarietyChange}
              className="relative w-full h-[3.625rem] cursor-default overflow-hidden rounded-[10px] bg-white text-left
                 focus:outline-none  focus-visible:ring-white/75 
                focus-visible:ring-0 sm:text-sm  px-2 text-black
                flex justify-between items-center border border-[#C4C4C4]
                "
            />
          </div>
        </div>

        <div className="flex gap-10 items-center mt-5">
          <div className="w-full">
            <label className="text-xl leading-7 text-[#C4C4C4]">
              Starting Date
            </label>
            <div
              className="relative w-full h-[3.625rem] mt-2 flex items-center
                 ring-1 ring-[#C4C4C4] rounded-[10px] "
            >
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => handleStartDate(date)}
                className="bg-white w-full flex-1 cursor-pointer px-4 text-xl leading-7 text-[#2A2A2A]"
              />

              <CiCalendar
                className="absolute right-2 h-6 w-6 text-[#C4C4C4]"
                aria-hidden="true"
              />
            </div>
          </div>

          <div className="w-full">
            <label className="text-xl leading-7 text-[#C4C4C4]">
              Ending Date
            </label>
            <div
              className="relative w-full h-[3.625rem] mt-2 flex items-center
                 ring-1 ring-[#C4C4C4] rounded-[10px] "
            >
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => handleEndDate(date)}
                className="bg-white w-full flex-1 cursor-pointer px-4 text-xl leading-7 text-[#2A2A2A]"
              />

              <CiCalendar
                className="absolute right-2 h-6 w-6 text-[#C4C4C4]"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
