import React from 'react'
import { useNavigate } from 'react-router-dom'
import WarehouseSetupImage from '../assets/images/warehouse_setup.png'

const SetupWelcome = ({url} : {url : string, }) => {
    const navigate = useNavigate();
  return (
    <div style={styles.body}>
        <div style={styles.bodyInner}>
            <div className='w-full'>
                <h1 className='font-bold text-2xl'>Welcome !</h1>
                <h4 style={{fontWeight : "normal"}}>Please setup  your warehouse  to continue</h4>
            </div>

            <div data-aos = "fade-up" style={{display : 'grid', placeItems : "center", width : "100%", minHeight : "90vh"}}> 
                <div style={{width : "100%", maxWidth : "400px", display : "flex", flexDirection : "column", alignItems : "center"}}>
                    <div >
                        <img src={WarehouseSetupImage} alt="" />
                    </div>

                    <button onClick={()=> navigate(`${url}`)} style={styles.button}>Continue to setup</button>
                </div>
            </div>
        </div>

    </div>
  )
}

export default SetupWelcome



const styles : any = {
    body : {
        width : "100%",
        padding : "20px",
        color : "var(--text)"
    },

    bodyInner : {
        width : "100%"
    },

    button : {
        width: "100%",
        maxWidth : "300px",
        border: "none",
        outline: "none",
        backgroundColor: "var(--primeCol)",
        color: "var(--white)",
        padding: "14px 16px",
        borderRadius: "15px",
        height: "50px",
        cursor: "pointer",
        marginTop: "15px",
    }
}