import { createSlice } from "@reduxjs/toolkit";
import { fetchClients } from "../../actions/clients";

interface Client {
  name: string;
}

interface ClientsState {
  isLoading: boolean;
  error: null;
  failedMsg: string;
  clients: Client[];
  meta: {};
  clientDetail: { [code: string]: Client };
  clientDetails: {};
  // farmersDetails: farmersDetails[];
}

const initialState: ClientsState = {
  isLoading: false,
  error: null,
  failedMsg: "",
  clients: [],
  meta: {},
  clientDetail: {},
  clientDetails: {},
  // farmersDetails: [],
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    ClientDetails: (state, { payload }: any) => {
      const code = payload;
      const clientDetail = state.clientDetail[code];
      state.clientDetails = clientDetail;
      return;
    },
    AdminDetails: (state, { payload }: any) => {
      const id = payload;
      const adminDetails = state.clientDetails
    },
    AgentDetails: (state, { payload }: any) => {},
    FarmerDetails: (state, { payload }: any) => {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchClients.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;

        const {
          data,
          current_page,
          first_page_url,
          last_page_url,
          next_page_url,
          path,
          total,
          to,
          links,
        } = payload.msg;

        state.meta = {
          current_page,
          first_page_url,
          last_page_url,
          next_page_url,
          path,
          total,
          to,
          links,
        };

        state.clients = data;

        state.clientDetail = data.reduce(
          (acc: any, client: any) => ({
            ...acc,

            // client details
            [client.code]: {
              id: client.id,
              name: client.name,
              email: client.email,
              country: client.country.name,
              state: client.state.name,
              city: client.city,
              logo: client.logo,
              admins_count: client.admins_count,
              agents_count: client.agents_count,
              farmers_count: client.farmers_count,

              // farmers
              farmers: client.farmers,
              // farmers details
              farmersDetails: client.farmers.reduce(
                (acc: any, farmer: any) => ({
                  ...acc,
                  [farmer.id]: {
                    id: farmer.id,
                    name: farmer.name,
                    phone: farmer.phone,
                    image: farmer.image,
                  },
                }),
                {}
              ),
              farmerDetail: {},

              // admins
              admins: client.admins,
              // admins details
              adminsDetails: client.admins.reduce(
                (acc: any, admin: any) => ({
                  ...acc,
                  [admin.id]: {
                    id: admin.id,
                    name: admin.name,
                    email: admin.email,
                    phone: admin.phone,
                    image: admin.image,
                  },
                }),
                {}
              ),
              adminDetail: {},

              // agents
              agents: client.agents,
              // agents details
              agentsDetails: client.agents.reduce(
                (acc: any, agent: any) => ({
                  ...acc,
                  [agent.id]: {
                    id: agent.id,
                    name: agent.name,
                    email: agent.email,
                    phone: agent.phone,
                    image: agent.image,
                  },
                }),
                {}
              ),
              agentDetail: {},
            },
          }),
          {}
        );
      })
      .addCase(fetchClients.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        state.error = payload.msg;
      });
  },
});

export const clientsActions = clientsSlice.actions;
export default clientsSlice.reducer;
