import { FaEllipsisV } from "react-icons/fa";

const TableTile = ({
  serviceType,
  description,
  date,
  country,
  landSize,
}: any) => {
  return (
    <tr
      style={{ marginBottom: "30px", paddingTop: "30px" }}
      className="border-b-2 border-[#C4C4C4] pt-5">
      <td className="w-52 pr-8 text-[14px] font-semibold">
        <p>{serviceType}</p>
      </td>
      <td className="w-52 pr-8 text-[#C4C4C4]  text-[14px] font-normal">
        <p className="mt-4 mb-1">{description}</p>
      </td>

      <td className=" pr-8 text-[14px] font-medium">
        <p>{date}</p>
      </td>
      <td className=" w-52 pr-8 text-[14px] font-medium">
        <p>{country}</p>
      </td>
      <td className=" h-full text-[14px] font-medium ">
        <div className="flex justify-between p-0 items-center pr-2">
          <p>{landSize}yard</p>
          <div>
            <button className="text-xs py-2 cursor-pointer px-4 rounded-lg flex gap-3 items-center font-medium border border-[#C4C4C4]  hover:border-[#37847E] focus:border-[#37847E] focus:outline-none ">
              <span className="block">Action</span>
              <FaEllipsisV />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

const ServiceTable = ({ tableHeadContents, serviceList }: any) => {
  return (
    <div
      className="bg-white px-8 py-10 my-5"
      data-aos="fade-up"
      data-aos-delay="100">
      <table id="table" className="border-collapse">
        <thead className="">
          <tr className="border-b-2 border-[#C4C4C4]">
            {tableHeadContents.map((tableHeadContent: any, i: number) => (
              <th
                key={i}
                className="text-[#C4C4C4] text-start text-[15px] font-normal px-0 py-1">
                {tableHeadContent}
              </th>
            ))}
          </tr>
        </thead>

        {/* <tbody>
          <tr >
            <td colSpan={5} style={{ backgroundColor: "#ffff" }}>
              <div className="flex justify-center mt-16 ">
                <div className="load__indicator__box">
                  <div className="load__indicator"></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody> */}

        <tbody>
          {!serviceList && (
            <tr className="cursor-default mt-6 bg-inherit text-gray-400 hover:text-gray-400 hover:bg-inherit">
              <td colSpan={5}>
                <div>
                  <h4 className="text-center font-[500] text-lg my-10 ">
                    No accepted service yet!
                  </h4>
                </div>
              </td>
            </tr>
          )}
          {serviceList?.map((service: any, i: number) => (
            <TableTile key={i} {...service} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ServiceTable;
