import { useEffect, useState } from "react";
import {} from "@heroicons/react/24/outline";

import { FiUser } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCycleActions } from "../../redux/slice/cycles/addCycleSlice";
import { STORE_ACTION_TYPES } from "../../redux/types";
import { createCycle } from "../../redux/actions/cycles";
import { toast } from "react-toastify";
import { Farmer, Group } from "./components";

const AddCycle = () => {
  const [showFarmerCycle, setShowFarmerCycle] = useState(true);
  const [showGroupCycle, setShowGroupCycle] = useState(false);

  const { cycleLoading, cycleData, failedMsg, success } = useSelector(
    (state: any) => state.addCycle
  );
  const companyId = useSelector((state: any) => state.admin.user.id);

  const admin_id = useSelector((state: any) => state.admin.user.id);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const submitFarmerForm = async () => {
    const updatedCycleData = {
      ...cycleData,
      admin_id,
      company_id: companyId,
    };
    createCycle(dispatch, updatedCycleData);
    
  };

  const submitGroupForm = async () => {
    const updatedCycleData = {
      ...cycleData,
      is_group: 1,
      admin_id,
      company_id: companyId,
    };
    createCycle(dispatch, updatedCycleData);
    console.log("Cycle Data", updatedCycleData);
  };

  useEffect(() => {
    if (success) {
      toast.success("Cycle Created Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {
        navigate("/cycles");
        dispatch(addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.FAILED](""));
      }, 3000);
    }
  }, [dispatch, navigate, success]);

  return (
    <div className="add_cycle">
      <div className="add_cycle_inner ">
        <div className="max-w-[44.75rem] mt-12 bg-white px-10 py-12 mx-auto">
          <div className="flex justify-between items-center ">
            <h1 className="font-semibold text-3xl leading-10 text-[#2A2A2A]">
              Add Cycle Details
            </h1>
            <button
              className="w-[9.688rem] h-14 rounded-lg flex justify-center
             items-center bg-[#37847E] font-semibold text-base leading-6 text-white"
              onClick={showFarmerCycle ? submitFarmerForm : submitGroupForm}
            >
              {cycleLoading ? "LOADING....." : "Save Cycle"}
            </button>
          </div>

          <div className="flex items-center gap-8 mt-5">
            <div
              onClick={() => {
                setShowFarmerCycle(true);
                setShowGroupCycle(false);
              }}
              className={`flex items-center gap-2 pb-2 cursor-pointer border-b-[3px]
              ${
                showFarmerCycle
                  ? "text-[#37847E] border-[#37847E] "
                  : "text-[#2A2A2A] border-transparent"
              } `}
            >
              <FiUser
                className={`w-6 h-6 ${
                  showFarmerCycle ? "text-[#37847E]" : "text-[#2A2A2A]"
                }
               `}
              />
              <span className="text-xl leading-8">Farmer Cycle</span>
            </div>
            <div
              onClick={() => {
                setShowGroupCycle(true);
                setShowFarmerCycle(false);
              }}
              className={`flex items-center gap-2 pb-2  border-b-[3px] cursor-pointer
              ${
                showGroupCycle
                  ? "text-[#37847E] border-[#37847E] "
                  : "text-[#2A2A2A] border-transparent"
              } `}
            >
              <FiUsers
                className={`w-6 h-6 ${
                  showGroupCycle ? "text-[#37847E]" : "text-[#2A2A2A]"
                }
               `}
              />
              <span className="text-xl leading-8">Group Cycle</span>
            </div>
          </div>

          {showFarmerCycle ? <Farmer /> : <Group />}

          <div className="flex justify-end items-center mt-7">
            {/* <div className="font-medium text-base leading-6 text-[#37847E] cursor-pointer">
              + Add another Cycle
            </div> */}

            <button
              onClick={() => navigate(-1)}
              className="w-[8.906rem] h-[3.625rem] flex justify-center items-center bg-[#E5E5E5]
            rounded-md text-xl leading-8 text-[#2A2A2A] "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCycle;
