const ExtractSizeParameter = (url: string): string | null => {
  const pageRegex = /(?:\?|&)page=([^&]+)/;
  const match = url.match(pageRegex);
  if (match && match[1] !== undefined) {
    return `page=${match[1]}`;
  } else {
    return null;
  }
};

export default ExtractSizeParameter;
