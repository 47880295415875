import {createContext, useContext, useReducer, } from 'react'
import { DataContextInterface} from '../db/interfaces'
import { initialState, reducer } from './reducer'


// [GlobalState, Dispatch<PayLoad>] 

const DataContext = createContext({state : initialState} as DataContextInterface)

export const DataProvider: React.FC<{children : any}> = ({children})=>{
    let [state,dispatch] = useReducer(reducer,initialState)
    return(
        <DataContext.Provider   value={{state,dispatch}}>
            {children}
        </DataContext.Provider>
    )
}

export const useStateValue = ()=> useContext(DataContext)


