import React from "react";
import { useNavigate } from "react-router-dom";
import { clearCachedUser } from "../service/common";
import { MdOutlineAccessTime } from "react-icons/md";
import { useDispatch } from "react-redux";
import { STORE_ACTION_TYPES } from "../redux/types";
import { adminLoginActions } from "../redux/slice/auth/adminLoginSlice";

const ExpiredSessionModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    clearCachedUser();
    navigate("/");
    window.location.reload();
    dispatch(adminLoginActions[STORE_ACTION_TYPES.AUTH.EXPIRED](false));
  };

  return (
    <div className="fixed top-0 bottom-0 h-screen w-full z-[1010] flex justify-center items-center" style={{background: "rgba(0, 0, 0, 0.2)"}}>
      <div className="w-[20rem] sm:w-[23rem] bg-white  mb-24 pb-2 rounded" data-aos="fade-down">
        <div id="header" className="bg-[#37847E] py-3 px-3 rounded-t">
          <h3 className="text-white font-semibold text-lg">Session Expired</h3>
        </div>
        <div id="form" className="my-8 mx-8">
          <div className="flex gap-2 justify-start">
            <div>
              <MdOutlineAccessTime className="text-[#37847E] text-3xl" />
            </div>
            <div className="pt-2">
              <p className="text-[#7a7a7a] text-base mb-3">
                Your session has expired.
              </p>
              <p className="text-[#7a7a7a] text-base">
                You will be redirected to the Login page.
              </p>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <button
              className=" text-white shadow-lg shadow-neutral-500 bg-[#37847E] delay-100 hover:bg-[#27635e] focus:bg-[#37847E] font-normal py-1 rounded px-8 text-lg cursor-pointer"
              onClick={handleClick}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredSessionModal;
