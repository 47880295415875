import { useState } from 'react';
import { MdOutlinePerson, MdOutlineLock, MdOutlineMail, MdOutlinePhone} from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import { toggleDarkMode } from '../functions/navbar'
import { axiosInstance } from '../redux/apis/axios';
import { ADMIN_TOKEN } from '../state_manager/constants';
import { useStateValue } from '../state_manager/contextApi';
import '../styles/settings.css'
import { getCachedToken } from '../service/common';

const Settings = () => {
  const {state : {theme}, dispatch}= useStateValue();
  const {state} = useStateValue();
  const {user} = state;
  const isDark = theme === "dark";



  const credentialDataObj = {
    name : user?.name ?? user?.admin?.name ,
    email : user?.email ?? user?.admin?.email  ,
    password : user?.password ?? user?.admin?.password ,
    phone: user?.phone  ?? user?.admin?.phone  ,
    old_password : "",
    c_password : ""
  }

  const [credentialData , setCredentialData] = useState(credentialDataObj);
  const [credentialChangeType, setCredentialChangeType] = useState('profile');
  const [updateLoading, setUpdateLoading] = useState(false)

  const handleCredentialChange = (e:any)=>{
    const {name, value} = e.target;
    setCredentialData({...credentialData, [name] : value})
  }


  const handleCrendentialUpdate = async(e : any)=>{
    e.preventDefault();

    const token  = getCachedToken();

    setUpdateLoading(true);

    await axiosInstance.post(`/admin/api/profile/update`,{type : credentialChangeType, ...credentialData }, {headers : {"Authorization" : `Bearer ${token?.token}`}})
    .then(res => {
      console.log(res);
      toast('went through');
      setUpdateLoading(false)
    })
    .catch((err)=> {setUpdateLoading(false); console.log(err)})
  }



  return (
    <div className='settings' data-aos = "fade-left">
      <ToastContainer position='top-center' hideProgressBar autoClose = {1500} />

      {/* THEME */}
      <div className='theme_cont'>
        <div className='title_cont'>
          <h4>THEME</h4>
        </div>
        <div className='body_cont'>
          <h3>Dark Theme</h3>
          <div className='dark_mode_toggle_cont' onClick={()=>toggleDarkMode(theme,dispatch)}>
            <span>🌜</span>
            <span>🌞</span>
            <div className={`dark_mode_indicator ${ isDark ? 'dark_mode_indicator_active' : ''}`}></div>
          </div>
        </div>

      </div>

      {/* CREDENTIALS */}
      <div className='credential_update_cont'>
        <div className='title_cont'>
          <h4>CREDENTIAL UPDATE</h4>
        </div>
        <div className='body_cont'>

          <form action="" onSubmit={handleCrendentialUpdate}>
            <h4 style={{fontWeight : "500", padding : '10px 0'}}>What type of credential do you want to change</h4>
            <div style={{display : 'flex', gap : '20px', padding : '10px 0'}}>
              <label><input type="radio" defaultChecked name="type" id=""  value={'profile'} onClick = {()=>setCredentialChangeType('profile')}/> Profile</label>
              <label><input type="radio" name='type' value={'password'} onClick = {()=>setCredentialChangeType('password')} />  Password</label>
            </div>

            {
              credentialChangeType === 'profile' ?
              (
                <>
                  <div className='input_cont'>
                    <div className='input_icon_cont'>
                      <MdOutlinePerson className='auth_input_icon'/>
                    </div>
                    <input type="text" required name='name' value={credentialData.name} onChange = {handleCredentialChange} placeholder='username' />
                  </div>

                  <div className='input_cont'>
                    <div className='input_icon_cont'>
                      <MdOutlineMail className='auth_input_icon'/>
                    </div>
                    <input type="text" required name='email' value={credentialData.email} onChange = {handleCredentialChange}  placeholder='email' />
                  </div>

                  <div className='input_cont'>
                    <div className='input_icon_cont'>
                      <MdOutlinePhone className='input_icon'/>
                    </div>
                    <input type="number" required name='phone' value={credentialData.phone} onChange = {handleCredentialChange} placeholder='phone number' />
                  </div>
                </>
              )

              :

              (
                <>
                  <div className='input_cont'>
                    <div className='input_icon_cont'>
                      <MdOutlineLock className='input_icon'/>
                    </div>
                    <input type="password" required name='password' value={credentialData.password} onChange = {handleCredentialChange} placeholder='old password' />
                  </div>

                  <div className='input_cont'>
                    <div className='input_icon_cont'>
                      <MdOutlineLock className='input_icon'/>
                    </div>
                    <input type="password" required name='old_password' value={credentialData.old_password} onChange = {handleCredentialChange} placeholder='new password' />
                  </div>

                  <div className='input_cont'>
                    <div className='input_icon_cont'>
                      <MdOutlineLock className='input_icon'/>
                    </div>
                    <input type="password" required name='c_password' value={credentialData.c_password} onChange = {handleCredentialChange}placeholder='confirm password' />
                  </div>
                </>
              )

            }





            <button className='setting_button' onClick={handleCrendentialUpdate}>
              {
                updateLoading ? 'LOADING....' : 'UPDATE'
              }
            </button>

        </form>

        </div>

      </div>

    </div>
  )
}

export default Settings
