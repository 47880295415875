import React, { useEffect, useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import FormInput from '../../components/form_input';
import { AddGroup } from '../../redux/apis/groups.service';
import { useSelector } from 'react-redux';
import { ButtonLoading } from '../../components/loading';

const GroupAdd = () => {

    const navigation = useNavigate();
    const [submitting, setSubmitting] = useState(false)
    const {user} = useSelector((state: any)=> state.admin)

    const [formData,setFormData] = useState(
        {
            name :"",
            capacity: ""
        }
    );


    const handleSubmit = async()=>{
        if(!formData.capacity || !formData.name){
            toast.error('Both fields required.')
            return
        }

        setSubmitting(true)
       AddGroup({
            ...formData, 
            company_id: user?.company_id,
            admin_id: user?.id
        })
       .then((res)=>{
            const {msg, code} = res.data
            setSubmitting(false) 
            if(code !== 200){
                toast.error('Failed to add group.')
                return
            }
            toast.success(msg)
            setFormData({name: '', capacity: ''})
            
       })
       .catch((err)=>{
            console.log("Add Group Error :: ", err.message)
            toast.error('Failed to add group.')
            setSubmitting(false)
       })

    }


    // useEffect(()=>{
    //     getRegions()
    //     .then(
    //         res => res.data.code === 200 &&
    //         setRegions(res.data.msg.map(
    //             (regionObj: any) => ({val : regionObj.id, text : regionObj.name})
    //         ))
    //     )
    // },[])


  return (
    <div className='warehouse_add'>
        <ToastContainer
            hideProgressBar
            position="top-center"
            autoClose={1800}
            containerId={"fieldAgentDashToast"}
        />
        <div className="close_container">
            <div className="close_btn"  onClick={()=>navigation(-1)}>
                <MdArrowBack/>
            </div>
        </div>




        <div style={{width : "100%", maxWidth : "600px", margin : "auto",marginTop : "50px", backgroundColor : 'var(--white)', padding : '30px 20px', borderRadius : '15px'}}>
            <div style={{width : "100%", maxWidth : "1200px", marginBottom : "30px"}}>
                <h1 className='font-bold text-lg' >Add Group</h1>
            </div>
            <FormInput title="Group Name" name="name" type="text" setValue = {setFormData} data = {formData}  />
            <FormInput title="Capacity" name="capacity" type="text" setValue = {setFormData} data = {formData}  />

            <button className='btn' onClick={handleSubmit} style={{marginTop : "30px"}}>
                {submitting ? <ButtonLoading/> : 'Submit'}
            </button>
        </div>

    </div>
  )
}

export default GroupAdd
