import { GetGroupDetails, GetGroups } from "../apis/groups.service";
import { groupsActions } from "../slice/groups/groupsSlice";
import { STORE_ACTION_TYPES } from "../types";

export const requestGroups = async (
  dispatch: any,
  adminId: number,
  company_id: number,
  paginate?: string
) => {
  paginate ?? dispatch(groupsActions[STORE_ACTION_TYPES.GROUPS.REQUEST](""));
  try {
    const response = await GetGroups(company_id, adminId, paginate);

    const { msg, code } = response.data;

    // console.log(response.data)

    if (code !== 200) {
      dispatch(
        groupsActions[STORE_ACTION_TYPES.GROUPS.FAILED](
          "Failed to fetch groups"
        )
      );
    }

    dispatch(groupsActions[STORE_ACTION_TYPES.GROUPS.RESPONSE](msg));
  } catch(e) {
    console.log(e)
    dispatch(
      groupsActions[STORE_ACTION_TYPES.GROUPS.FAILED]("Failed to fetch groups")
    );
  }
};

export const requestGroupDetails = async (
  dispatch: any,
  group_id: string,
  company_id: string
) => {
  try {
    const response = await GetGroupDetails(group_id, company_id);
    const { msg, code } = response.data;
    if (code !== 200) {
      return;
    }
    dispatch(groupsActions[STORE_ACTION_TYPES.GROUPS.VIEW_GROUP](msg));
  } catch {
    dispatch(
      groupsActions[STORE_ACTION_TYPES.GROUPS.FAILED](
        "Failed to fetch farmer detail"
      )
    );
  }
};
