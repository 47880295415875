export const asp_data = [
  { text: "Input", color: "#E4B29A" },
  { text: "Food Processor", color: "#37847E" },
  { text: "Cold Storage", color: "#FFE500" },
  { text: "Warehouse", color: "#F14647" },
  { text: "Grain drying facility", color: "#00D26A" },
  { text: "Insurance", color: "#9747FF" },
  { text: "Mechanization Service", color: "#533566" },
  { text: "Financial Institution", color: "#F548E3" },
  { text: "Access to Labour", color: "#C1FFC7" },
  { text: "Logistics", color: "#B00020" },
  { text: "Soil Testing", color: "#2A2A2A" },
  { text: "Green-Housing ", color: "#52FF00" },
  { text: "Irrigation", color: "#C4C4C4" },
  { text: "Access to Land", color: "#0AC3EC" },
];
