import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Layout from "./components/layout";
import Auth from "./pages/auth";
import Home from "./pages/home";
import Settings from "./pages/settings";
import AOS from "aos";
import "aos/dist/aos.css";

import Farmers from "./pages/farmers/farmers";
import FarmerDashboard from "./pages/farmers/farmer_dashboard";
import FarmerWallet from "./pages/farmers/farmer_wallet";
import FarmerTransactions from "./pages/farmers/farmer_transactions";

import Warehouses from "./pages/warehouses/warehouses";
// import WarehouseDashboard from './pages/warehouses/warehouse_dashboard';

import { useEffect } from "react";
import FieldAgents from "./pages/field_agents/field_agents";
import WarehouseTransactions from "./pages/warehouses/warehouse_transactions";
import FieldAgentDashboard from "./pages/field_agents/field_agent_dashboard";
import Clients from "./pages/companies/clients/clients";
import FarmerAdd from "./pages/farmers/farmer_add";
import FieldAgentAdd from "./pages/field_agents/field_agent_add";
import FarmerEdit from "./pages/farmers/farmer_edit";
import FieldAgentEdit from "./pages/field_agents/field_agent_edit";
import { Loading } from "./components/loading";
import ClientAdd from "./pages/companies/clients/client_add";
import Groups from "./pages/groups/groups";
import Cycles from "./pages/cycles/cycles";
import AddCycle from "./pages/cycles/add_cycle";
import CycleDetails from "./pages/cycles/cycle_details";
import WarehouseAdd from "./pages/warehouses/warehouse_add";
import WarehouseDetails from "./pages/warehouses/warehouse_details";
import InventoryAdd from "./pages/warehouses/inventory_add";
import GroupAdd from "./pages/groups/group_add";
import { useDispatch, useSelector } from "react-redux";
import { requestAdminDetails } from "./redux/actions/auth";
import Dashboards from "./pages/dashboards";
import ClientDetails from "./pages/companies/clients/client_detail";
import AddAdmin from "./pages/companies/admins/add_admin";
import AdminDetails from "./pages/companies/admins/admin_details";
import Service from "./pages/service/service";
import ServicesRequested from "./pages/service/service_requested";
import ServicesAccepted from "./pages/service/service_accepted";
import GroupDetail from "./pages/groups/group_detail";

AOS.init();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { user, isLoading } = useSelector((state: any) => state.admin);
  const { is_super_admin }: { is_super_admin: number } = user;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!user) requestAdminDetails(dispatch);
  }, [dispatch, user]);

  if (isLoading) return <Loading />;

  return (
    <div className="app">
      <Router>
        <Routes>
          {!user ? (
            <Route path="/*" element={<Auth />} />
          ) : (
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboards />} />
              <Route path="farmers" element={<Farmers />} />
              <Route path="farmeradd" element={<FarmerAdd />} />
              <Route path="farmers/details" element={<FarmerDashboard />} />
              <Route path="farmers/details/edit/:id" element={<FarmerEdit />} />
              <Route path="farmers/details/wallet" element={<FarmerWallet />} />
              <Route
                path="farmers/details/wallet/transactions"
                element={<FarmerTransactions />}
              />

              <Route path="warehouses" element={<Warehouses />} />
              <Route path="warehouseadd" element={<WarehouseAdd />} />
              <Route path="inventoryadd" element={<InventoryAdd />} />
              <Route path="warehouses/:id" element={<WarehouseDetails />} />
              <Route
                path="warehouses/:id/transactions"
                element={<WarehouseTransactions />}
              />

              <Route path="fieldagents" element={<FieldAgents />} />
              <Route path="fieldagentadd" element={<FieldAgentAdd />} />
              <Route
                path="fieldagents/details/edith"
                element={<FieldAgentEdit />}
              />
              <Route
                path="fieldagents/details"
                element={<FieldAgentDashboard />}
              />
              <Route path="services" element={<Service />} />
              <Route
                path="services/requested"
                element={<ServicesRequested />}
              />
              <Route path="services/accepted" element={<ServicesAccepted />} />

              <Route path="groups/" element={<Groups />} />
              <Route path="groups/details" element={<GroupDetail />} />
              <Route path="groupadd/" element={<GroupAdd />} />

              {is_super_admin === 1 && (
                <>
                  <Route path="clients" element={<Clients />} />
                  <Route path="clients/details" element={<ClientDetails />} />
                  <Route
                    path="clients/details/admin"
                    element={<AdminDetails />}
                  />
                  <Route path="/clientadd" element={<ClientAdd />} />
                  <Route path="/add-admin" element={<AddAdmin />} />
                </>
              )}

              <Route path="cycles" element={<Cycles />} />
              <Route path="cycles/cycleadd" element={<AddCycle />} />
              <Route path="cycles/details" element={<CycleDetails />} />

              <Route path="settings" element={<Settings />} />
            </Route>
          )}

          <Route path="/auth" element={<Auth />} />
        </Routes>
        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;
