import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";

const ListAgents = () => {
  const { agents } = useSelector((state: any) => state.clients.clientDetails);
  return (
    <div className="list_farmers_cont">
      <div className="h-[50px] mb-[20px] border-b border-b-[#C4C4C4] w-[100%]">
        <h1 className="text-[18px] text-center font-bold w-[100%]">Agents</h1>
      </div>
      {!agents ? (
        <div
          style={{
            width: "100%",
            minHeight: "400px",
            display: "grid",
            placeItems: "center",
          }}
        >
          No Clients yet...
        </div>
      ) : (
        <table>
          <thead className="table_head">
            <tr className="table-row">
              {["LOGO", "NAME", "PHONE", "EMAIL"].map((tableHeadContent, i) => (
                <th key={tableHeadContent} className="table_head_content">
                  {tableHeadContent}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {agents && agents.length > 0 ? (
              agents.map((agent: any) => (
                <tr className="table-row">
                  <td>
                    <Avatar
                      src={agent.image}
                      alt={agent.name}
                      className="t_img"
                    />
                  </td>
                  <td>{agent.name}</td>
                  <td>{agent.phone}</td>
                  <td>{agent.email}</td>
                </tr>
              ))
            ) : (
              <tr className="table-row">
                <td colSpan={5}>No data yet</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ListAgents;
