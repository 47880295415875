import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";

export const GetClients = async (admin_id: number, page: any) =>
  await getAxios(BACKEND_BASE_URL).post(`/company?${page ? page : ''}`, {admin_id});


export const CreateClient = async (client_data: any)=>
  await getAxios(BACKEND_BASE_URL).post(`/company/register`,client_data)

