import { useState } from "react";
import { MdArrowBack, MdOutlineSearch } from "react-icons/md";
import { RiFilterOffLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ServiceTable from "../../components/service/service_table";

const ServicesRequested = () => {
  const navigation = useNavigate();
  const [searchData, setSearchData] = useState({ keyword: "", type: "name" });
  const onSearchSubmit = async () => {};

  // static data
  const listAcceptedServices = [
    {
      serviceType: "Tractor Service",
      description: "Lorem ipsum Lorem ipsum Lorem ipsum ",
      date: "5/05/2023",
      country: "Ghana",
      landSize: "23",
    },
    {
      serviceType: "Tractor Service",
      description: "Lorem ipsum Lorem ipsum Lorem ipsum ",
      date: "5/05/2023",
      country: "Ghana",
      landSize: "23",
    },
    {
      serviceType: "Tractor Service",
      description: "Lorem ipsum Lorem ipsum Lorem ipsum ",
      date: "5/05/2023",
      country: "Ghana",
      landSize: "23",
    },
  ];

  const tableHeadContents = [
    "Service",
    "Description",
    "Date",
    "Country",
    "Land Size",
  ];

  return (
    <div className="Services w-full  m-auto p-3 md:p-5  max-w-[1200px] min-h-screen">
      <div className="flex justify-between items-center">
        <div className="close_container flex gap-5 items-center">
          <div className="close_btn" onClick={() => navigation(-1)}>
            <MdArrowBack />
          </div>
          <h1 className="title text-[28px] font-semibold text-[#2A2A2A]">
            Request
          </h1>
        </div>
        <div className="flex gap-4 items-center">
          <div className="search_bar flex items-center justify-center bg-white h-10 w-72 rounded-[10px] border-red-500 py-[8px] px-3">
            <MdOutlineSearch className="search_icon ml-2" color="#C4C4C4" />
            <input
              className="text-[#2A2A2A] outline-none bg-inherit h-full pr-1"
              type="text"
              placeholder="Search Location"
              onChange={(e) =>
                setSearchData({ ...searchData, keyword: e.target.value })
              }
              onKeyDown={(e) => e.key === "Enter" && onSearchSubmit()}
            />
          </div>
          <div>
            <button className="text-sm py-2 cursor-pointer px-3 rounded-lg flex gap-3 items-center font-normal border border-[#C3C3C3] bg-white  hover:border-[#37847E] focus:border-[#37847E] focus:outline-none ">
              <RiFilterOffLine size={15} />
              <span className="block">Filter</span>
            </button>
          </div>
        </div>
      </div>
      <ServiceTable
        tableHeadContents={tableHeadContents}
        serviceList={listAcceptedServices}
      />
      <section className="my-10">
        <h2 className="title text-[18px] font-semibold text-[#2A2A2A]">Request Statistics</h2>
      </section>
    </div>
  );
};

export default ServicesRequested;
