import { MdOutlineSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "../../styles/field_agents.css";
import { useEffect, useState } from "react";
import { Loading2 } from "../../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { requestGroups } from "../../redux/actions/groups";
import Paginator from "../../components/paginator";
import { groupsActions } from "../../redux/slice/groups/groupsSlice";

interface Group {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  capacity: number;
  handleClick: (id: string) => void;
}

const TableTile = ({
  id,
  name,
  capacity,
  created_at,
  updated_at,
  handleClick,
}: Group) => {
  const navigate = useNavigate();
  const created_at_local = new Date(created_at).toDateString();
  const updated_at_local = new Date(updated_at).toDateString();

  return (
    <tr className="table-row" onClick={() => handleClick(id)}>
      <td>{id}</td>
      <td>{name}</td>
      <td>{capacity}</td>
      <td>{created_at_local}</td>
      <td>{updated_at_local}</td>
    </tr>
  );
};

const Groups = () => {
  const [groupListLoading, setGroupListLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const tableHeadContents = [
    "ID",
    "NAME",
    "CAPACITY",
    "CREATED AT",
    "UPDATED AT",
  ];

  const { groups, isLoading, meta, failedMsg } = useSelector(
    (state: any) => state.groups
  );

  const { user } = useSelector((state: any) => state.admin);
  const dispatch = useDispatch();
  console.log(groups, "groups");

  const handleClick = (code: any) => {
    dispatch(groupsActions.GroupDetails(code));
    navigate("details");
  };

  useEffect(() => {
    requestGroups(dispatch, user?.id, user?.company_id)
  }, []);

  useEffect(() => {
    setGroupListLoading(false);
  }, [groups]);

  if (groupListLoading) return <Loading2 />;

  return (
    <div className="field_agents">
      <div className="field_agents_inner">
        <div className="search_filter_cont" data-aos="fade-up">
          <div className="search_cont">
            <MdOutlineSearch className="search_icon" />
            <input type="text" placeholder="search groups" />
          </div>
        </div>

        <Paginator meta={meta} addUrl="/groupadd" title="Add Groups" />

        {/* FIELD AGENTS TABLE */}
        <div
          className="list_farmers_cont"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="table_loading_cont">
            {isLoading && (
              <div className="table_loading_cont_inner">
                <div className="table_loading_indicator"></div>
              </div>
            )}
          </div>

          <table>
            <thead className="table_head">
              <tr className="table-row">
                {tableHeadContents.map((tableHeadContent, i) => (
                  <th key={i} className="table_head_content">
                    {tableHeadContent}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {groups?.map((group: any, i: number) => (
                <TableTile key={i} {...group} handleClick={handleClick} />
              ))}

              {groups?.length === 0 && (
                <tr>
                  <td>No Records</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>

          <div
            className="back_to_top"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <h4>Back to top</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Groups;
