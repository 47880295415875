import { getAxios } from "../../service/common";
import { BACKEND_HELPERS_BASE_URL } from "../../state_manager/constants";


export const GetCountries = async () =>
await getAxios(BACKEND_HELPERS_BASE_URL).get("/countries/list");

export const GetStates = async (country_id: any) =>
  await getAxios(BACKEND_HELPERS_BASE_URL).get(`/regions/list/${country_id}`);



