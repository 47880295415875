import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";

export const GetFarmers = async (
  admin_id: number,
  company_id: number,
  paginate?: string
) =>
  await getAxios(BACKEND_BASE_URL).post(
    `/company/farmers${paginate ? "?" + paginate : ''}`,
    { company_id: company_id, admin_id: admin_id }
  );


export const DisplayFarmers = async () =>
  await getAxios(BACKEND_BASE_URL).get(
    "/farmers"
  );

export const GetAllFarmers = async (
  admin_id: number,
) =>
  await getAxios(BACKEND_BASE_URL).post(
    `/company/list/all/farmers`,
    { admin_id: admin_id }
  );



export const GetFarmerDetails = async (farmer_id: any, company_id: any) =>
  await getAxios(BACKEND_BASE_URL).post(`/company/farmer/details`, {
    farmer_id, company_id
  });

export const GetFarmerFarmDetails = async (farmer_uid: string) =>
  await getAxios(BACKEND_BASE_URL).post(`/company/farmer/farms`, {farmer_uid: farmer_uid});
