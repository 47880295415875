import { useSelector } from "react-redux";
import { MdArrowBack, MdModeEdit } from "react-icons/md";
import { useNavigate} from "react-router-dom";

const FieldAgentEdit = () => {
  const { fieldAgentDetails } = useSelector((state: any) => state.fieldAgents);

  console.log(fieldAgentDetails);

  const navigation = useNavigate();

  return (
    <div>
      <div className="close_container">
        <div className="close_btn" onClick={() => navigation(-1)}>
          <MdArrowBack />
        </div>
      <h1>Edith page for field agent</h1>
      </div>
    </div>
  );
};

export default FieldAgentEdit;
