import "../styles/auth.css";
import { MdOutlineLock, MdOutlineEmail } from "react-icons/md";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import farmImg from "../assets/images/login_img.png";
import { useNavigate } from "react-router-dom";
import { AdminProfile } from "../db/interfaces";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../redux/apis/auth.service";
import { adminLoginActions } from "../redux/slice/auth/adminLoginSlice";
import { STORE_ACTION_TYPES } from "../redux/types";
import { cacheToken, cacheUser } from "../service/common";

const Auth = () => {
  const adminCredential: AdminProfile = {
    email: "",
    password: "",
  };

  const [user, setUser] = useState(adminCredential);
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const admin = useSelector((state: any) => state.admin);
  const dispatch = useDispatch();

  const showToastMessage = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 2000,
    });
  };

  const signin = async (e: any) => {
    e.preventDefault();
    setSignInLoading(true);

    let { email, password } = user;
    if (!email || !password) {
      showToastMessage("Email and Password are required!!");
      setSignInLoading(false);
      return;
    }

    try {
      const response = await Login(email, password);

      setSignInLoading(false);

      const { data } = response;

      cacheToken(data?.token, data?.expires_in, rememberMe);
      cacheUser(data.admin, rememberMe, dispatch);

      dispatch(
        adminLoginActions[STORE_ACTION_TYPES.AUTH.RESPONSE]({
          user: data?.admin,
        })
      );
    } catch (error: any) {
      setSignInLoading(false);
      const errorMessage =
        error.message === "Network Error"
          ? "Network error. Please check your internet connection."
          : "Invalid email or password";

      showToastMessage(errorMessage);
    }
  };

  useEffect(() => {
    if (admin) {
      admin?.user && navigate("/");
    }

    //this is to auto set user when we are offlien
    //dev purpose
    // dispatch({type : LOGIN, payload : {username : "kvng@gmail.ocm" , password : "password"}})
  }, [admin.user, admin, navigate, dispatch]);

  return (
    <div className="overflow-hidden md:h-screen">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between">
        {/* Left */}
        <div className=" left h-40 md:h-screen md:w-1/2">
          <img src={farmImg} alt="farm" className="w-full h-full" />
        </div>
        {/* Right */}
        <div
          data-aos="fade-up"
          className="h-full md:h-screen md:w-1/2 flex justify-center items-center bg-white">
          {/* BOTTOM */}
          <div className="auth_bottom mt-10 mb-20 md:my-0">
            <div className="auth_top mb-10">
              <div className="flex justify-center mb-8">
                <img src={logo} alt="lbh_logo" />
              </div>
              <div>
                <h1 className=" text-center mb-2 text-4xl">Welcome Back</h1>
                <p className="text-[#101313] text-center text-lg">
                  Sign in to continue
                </p>
              </div>
            </div>
            <form onSubmit={signin}>
              <div>
                <label htmlFor="email">Email</label>
                <div className="auth_input_cont">
                  <div className="auth_input_icon_cont border rounded-full p-1 border-[#C4C4C4] w-fit">
                    <MdOutlineEmail
                      size={15}
                      className="auth_input_icon text-[#C4C4C4] "
                    />
                  </div>
                  <input
                    type="email"
                    id="email"
                    autoComplete="off"
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    placeholder="email"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password">Password</label>
                <div className="auth_input_cont">
                  <div className="auth_input_icon_cont border rounded-full p-1 border-[#C4C4C4] w-fit">
                    <MdOutlineLock
                      size={15}
                      className="auth_input_icon text-[#C4C4C4] "
                    />
                  </div>
                  <input
                    type={isVisible ? "text" : "password"}
                    value={user.password}
                    id="password"
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    placeholder="password"
                  />
                  <div className="auth_input_icon_cont border rounded-full p-1 border-[#C4C4C4] cursor-pointer w-fit">
                    {isVisible ? (
                      <IoEyeOutline
                        className="auth_input_icon text-[#C4C4C4] "
                        size={15}
                        onClick={() => setIsVisible((prev) => !prev)}
                      />
                    ) : (
                      <IoEyeOffOutline
                        className="auth_input_icon text-[#C4C4C4] "
                        size={15}
                        onClick={() => setIsVisible((prev) => !prev)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="remember_me"
                    checked={rememberMe}
                    onChange={() => setRememberMe((prev) => !prev)}
                  />
                  <span className="text-xs text-[#868585]">Remember me</span>
                </label>
              </div>

              <button
                type="submit"
                className="auth_button"
                style={{
                  backgroundColor: signInLoading ? "gray" : "#37847E",
                  opacity: signInLoading ? 0.4 : 1,
                }}>
                {signInLoading ? "LOADING..." : "LOGIN"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
