import '../styles/loading.css'

// export const Loading = () => {
//   return (
//     <div className="loading">
//         <h4>LOADING..</h4>
//     </div>
//   )
// }


export const Loading = () => {
  return (
      <div className = "loading" >
          <div className = "loading__box">
              <h1 className='font-bold text-3xl'>LbH</h1>
              <div className = "load__indicator__box">
                  <div className = "load__indicator"></div>
              </div>

          </div>
          
      </div>
  )
}

export const Loading2 = ()=>{
    return(
        <div className='loading2'>
            {/* <h4>LOADING...</h4> */}
            <div>
              <h1 className='font-bold text-3xl' style={{textAlign : "center"}}>LbH</h1>
              <div className = "load__indicator__box">
                  <div className = "load__indicator"></div>
              </div>
            </div>
        </div>
    )
}





export const ButtonLoading:React.FC = ()=>{
  return (
      <div className = "btn__loading" >

      </div>
  )
}


