import { CountdownCircleTimer } from "react-countdown-circle-timer";

const CycleTimer = ({ start_date, end_date }: any) => {
  const startDate = new Date(start_date).getTime();
  const endDate = new Date(end_date).getTime();
  const currentTime = Date.now(); // Get current time

  // Total duration of the cycle in milliseconds
  const cycleDuration = endDate - startDate;
  // Duration of the cycle in seconds for the timer
  const cycleDurationSeconds = cycleDuration / 1000;

  // Calculate remaining time from current date to end date in seconds
  const remainingTimeToEnd = Math.max((endDate - currentTime) / 1000, 0);

  // Calculate remaining days from current to end date
  const remainingDaysToEnd = Math.ceil(remainingTimeToEnd / (60 * 60 * 24));

  // Check if the current date is before the start date
  const isBeforeStartDate = currentTime < startDate;

  // Calculate elapsed time since the start date only if current date is past the start date
  const elapsedTimeSinceStart = isBeforeStartDate ? 0 : currentTime - startDate;

  // Calculate the percentage of the cycle that has elapsed
  const elapsedPercentage = isBeforeStartDate
    ? 0
    : Math.round((elapsedTimeSinceStart / cycleDuration) * 100);

  // Remaining percentage of the cycle
  const remainingPercentage = 100 - elapsedPercentage;

  return (
    <div className="cycle_timer_cont flex flex-col">
      <CountdownCircleTimer
        isPlaying
        duration={cycleDurationSeconds} // total cycle duration in seconds
        initialRemainingTime={
          isBeforeStartDate ? cycleDurationSeconds : remainingTimeToEnd
        } // remaining time in seconds
        colors={["#05897C", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[7, 5, 2, 0]}
        strokeWidth={20}
      >
        {() => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3 className="font-extrabold text-4xl text-[#05897C]">
              {remainingPercentage}%
            </h3>
          </div>
        )}
      </CountdownCircleTimer>

      <h4 className="mt-10 font-bold">
        <span>{remainingDaysToEnd}</span> Days more to Harvest
      </h4>
    </div>
  );
};

export default CycleTimer;
