import React from "react";

const FormInput: React.FC<{
  title: string;
  name: string;
  type?: string;
  isSelect?: boolean;
  isSelectObj?: boolean;
  value?: string | number;
  setValue?: any;
  data?: any;
  pattern?: string
  placeholder?: string
  selectValues?: string[];
  selectValuesObj?: { name: string, id: number, val: any }[];
}> = ({
  title,
  type,
  isSelect,
  value,
  isSelectObj,
  selectValuesObj,
  setValue,
  name,
  data,
  selectValues,
  pattern,
  placeholder,
}) => {
  return (
    <div className="form_input w-full sm:w-1/2">
      <h3>{title}</h3>

      {isSelect ? (
        <select
          className="w-full"
          name={name}
          id=""
          required
          value={data ? data[name] : ""}
          onChange={(e) => setValue({ ...data, [name]: e.target.value })}>
          {selectValues?.map((selectValue: string, i: number) => {
            return (
              <option key={i} value={selectValue}>
                {selectValue}
              </option>
            );
          })}
        </select>
      ) : isSelectObj ? (
        <select
          className="w-full"
          name={name}
          id=""
          required
          value={data ? data[name] : ""}
          onChange={(e) => setValue({ ...data, [name]: e.target.value })}>
          {selectValuesObj?.map((selectValueObj, i: number) => {
            return (
              <option key={selectValueObj.id} value={selectValueObj.id}>
                {selectValueObj.name}
              </option>
            );
          })}
        </select>
      ) : (
        <input
          className="w-full"
          required
          type={type ? type : "text"}
          {... pattern &&{pattern: pattern}}
          {... placeholder &&{placeholder: placeholder}}
          name={name}
          value={data ? data[name] : ""}
          onChange={(e) => setValue({ ...data, [name]: e.target.value })}
        />
      )}
    </div>
  );
};

export default FormInput;
