import { createSlice } from "@reduxjs/toolkit";
import { STORE_ACTION_TYPES } from "../../types";

interface GroupDetails {
  id: string;
  name: string;
  // Add other properties here based on your actual data structure
}

interface GroupsState {
  isLoading: boolean;
  failedMsg: string;
  meta: {};
  groups: null | [];
  farmers: null | [];
  // viewGroup: {};
  groupDetails: {}; // Index signature for farmer details
  groupDetail: { [key: string]: GroupDetails };
}

const initialState: GroupsState = {
  isLoading: false,
  failedMsg: "",
  groups: null,
  farmers: null,
  // viewGroup: {},
  meta: {},
  groupDetails: {},
  groupDetail: {},
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    GroupDetails: (state, { payload }: any) => {
      const code = payload;
      const groupDetail = state.groupDetail[code];
      state.groupDetails = groupDetail;
      return;
    },
    [STORE_ACTION_TYPES.GROUPS.REQUEST]: (state) => {
      state.isLoading = true;
    },
    [STORE_ACTION_TYPES.GROUPS.RESPONSE]: (state, action) => {
      const { groups } = action.payload;
      const {
        data,
        current_page,
        first_page_url,
        last_page_url,
        next_page_url,
        path,
        total,
        to,
        links,
      } = groups;
      state.isLoading = false;
      state.groups = data;
      state.meta = {
        current_page,
        first_page_url,
        last_page_url,
        next_page_url,
        path,
        total,
        to,
        links,
      };
      state.groupDetail = data.reduce(
        (acc: any, group: any) => ({
          ...acc,
          [group.id]: {
            id: group.id,
            name: group.name,
            state: group.status,
            farmers: group.farmers,
          },
        }),
        {}
      );
    },
    [STORE_ACTION_TYPES.GROUPS.FAILED]: (state, action) => {
      state.isLoading = false;
      state.failedMsg = action.payload;
    },
    // [STORE_ACTION_TYPES.GROUPS.VIEW_GROUP]: (state, action) => {
    //   state.isLoading = false;
    //   state.viewGroup = action.payload;
    // },
  },
});

export const groupsActions = groupsSlice.actions;
export default groupsSlice.reducer;
