import {  createSlice } from '@reduxjs/toolkit';
import { STORE_ACTION_TYPES } from '../../types';

const initialState = {
  msg: '',
  user: '',
  sessionExpired: false,
  isLoading: false,
  error: null,
};

const adminLoginSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    [STORE_ACTION_TYPES.AUTH.REQUEST] : (state)=>{
      state.isLoading = true
    },
    [STORE_ACTION_TYPES.AUTH.RESPONSE] : (state, action)=>{
      const {user} = action.payload

      state.isLoading = false
      state.user = user ?? state.user
    },
    [STORE_ACTION_TYPES.AUTH.FAILED] : (state, action)=>{
      state.isLoading = false
      state.msg = action.payload ?? 'Failed to login'
    },
    [STORE_ACTION_TYPES.AUTH.LOGOUT] : ()=>{
      return initialState
    },
    [STORE_ACTION_TYPES.AUTH.EXPIRED] : (state, action)=>{
      state.sessionExpired = action.payload
    }



  },
});


export const adminLoginActions = adminLoginSlice.actions;
export default adminLoginSlice.reducer;
