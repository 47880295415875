import React from 'react'
import '../styles/sm_analysis.css'

import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);


interface SmAnalysisInterface {title : string, Icon1 : any, Icon2 : any, total : number, totalToday : number}

const SmAnalysis:React.FC<SmAnalysisInterface> = ({title, Icon1, Icon2, total, totalToday}) => {

  return (
    <div className='sm_analysis_cont'>
        <div className='sm_analysis_cont_inner'>
            <div className="sm_analysis_top">
                <div className='analysis_card' data-aos = "fade-up">
                    <div>
                        <div className='sm_analysis_icon_cont'>
                            <Icon1/>
                        </div>
                    </div>
                    <h2>
                        {total}
                    </h2>
                    <h4>
                        Total Number of {title}
                    </h4>

                </div>
                <div className='analysis_card' data-aos = "fade-up" data-aos-delay = "100">
                    <div>
                        <div className='sm_analysis_icon_cont'>
                            <Icon2/>
                        </div>
                    </div>
                    <h2>
                        {totalToday}
                    </h2>
                    <h4>Total Number of {title} today</h4>
                </div>
            </div>
            <div className="sm_analysis_bottom">
                {/* Graph */}

                <div className='sm_analysis_graph_cont' data-aos = "fade-up" data-aos-delay = "200" >


                    <Bar datasetIdKey='id' 
                        options={{
                            
                            color : "#05897C",
                            
                        }}
                        data ={{
                            labels: ['Jan','Feb','Mar','Apr','May','Jun', 'Jul', 'Aug'],
                            datasets: [
                                { label: `${title}`, data: [1, 6, 3, 4, 5, 8, 3, 2], backgroundColor : "#05897C"},
                                // { label: 'me', data: [3, 2, 1],},
                            ],
                        }}
                    />


                </div>

            </div>

        </div>

    </div>
  )
}

export default SmAnalysis