import { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdOutlineAnalytics,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Loading2 } from "../../components/loading";
import "../../styles/farmer_dashboard.css";
import {
  HiOutlinePencil,
  HiOutlineRefresh,
  HiOutlineTrash,
} from "react-icons/hi";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import calculateAge from "../../functions/calculateAge";
import { farmersActions } from "../../redux/slice/farmers/farmersSlice";

const FarmerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [cycleNum, setCycleNum] = useState(1);

  const { farmerDetails } = useSelector(
    (state: any) => state.farmers
    );
  const farmerInfo = farmerDetails;
  const farmsDetails = farmerDetails?.farms[cycleNum - 1];
  const farmsCount = farmerDetails?.farms.length
  const [isImageValid, setIsImageValid] = useState<boolean>(true);
  
  useEffect(() => {dispatch(farmersActions.FarmerDetails(farmerDetails.id))}, []);

  if (pageLoading) return <Loading2 />;

  return (
    <div className="farmer_dashboard p-5">
      <div className="close_container">
        <div className="close_btn" onClick={() => navigate(-1)}>
          <MdArrowBack />
        </div>
      </div>

      <div className="actions_cont" data-aos="fade-up">
        <div className="actions_cont_inner">
          <div className="actions_btn_cont">
            <div
              className="action_btn"
              title="delete farmer"
              onClick={() => {}}>
              <HiOutlineTrash style={{ color: "red" }} />
            </div>

            <div className="action_btn" title="refresh farmer data">
              <HiOutlineRefresh />
            </div>

            <div
              className="action_btn"
              title="edit farmer data"
              onClick={() => navigate("edit/" + farmerInfo?.id)}>
              <HiOutlinePencil />
            </div>
          </div>
        </div>
      </div>

      <div className="farmer_dashboard_container">
        <div className="farmer_profile">
          <div className="top">
            <div className="farmer_image">
              {isImageValid ? (
                <img
                  src={`https://backend.lbhfarm.com/storage/${farmerInfo?.image}`}
                  alt={farmerInfo?.name}
                  className="w-full h-full rounded-full"
                  onError={(e) => {
                    setIsImageValid(!isImageValid);
                  }}
                />
              ) : (
                <Avatar
                  src={farmerInfo?.image}
                  alt={farmerInfo?.farmerName}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "var(--primeCol)",
                  }}
                />
              )}
            </div>
            <div className="farmer_position">
              <h3>
                {/* Farmer   */}
                {/* <span><MdStar/></span> */}
              </h3>
            </div>
          </div>

          <div className="bottom">
            <div>
              <h4 className="font-bold">
                Name <br /> <span> {farmerInfo?.name} </span>
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                ID <br /> <span> {farmerInfo?.id} </span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                Unique Id <br /> <span> {farmerInfo?.farmer_uid} </span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                Gender <br /> <span> {farmerInfo?.gender}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                Age <br /> <span> {calculateAge(farmerInfo?.dob)} </span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                Status <br /> <span> {"Active"} </span>
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                Phone Number <br /> <span> {farmerInfo?.phone} </span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                House Number <br /> <span> {farmerInfo?.house_number}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                Digital Address <br />{" "}
                <span> {farmerInfo?.digital_address}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                District <br /> <span> {farmerInfo?.district}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                Farming Years <br /> <span>
                  {" "}
                  {farmerInfo?.farming_years}
                </span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                Country <br /> <span> {farmerInfo?.country}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                National Id Number <br />{" "}
                <span> {farmerInfo?.national_id_card}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                State<br /> <span> {farmerInfo?.state}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                Town <br /> <span> {farmerInfo?.town}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                {" "}
                No. of Farm Types <br /> <span> {farmerDetails?.farms?.length}</span>{" "}
              </h4>
            </div>
            <div>
              <h4 className="font-bold">
                Land Size <br />{" "}
                <span>{`${farmsDetails?.land_size} ${
                  parseInt(farmsDetails?.land_size, 10) > 0 ? "Acres" : "Acre"
                }`}</span>{" "}
              </h4>
            </div>
          </div>
        </div>

        <div className="container_right">
          <div className="farm_info">
            <h4 className="farm_info_title">Farm Info</h4>
            <div className="farm_details">
              <h4 className="font-bold">
                Farm Type
                <br /> <span>{farmsDetails?.get_farm_type.name}</span>{" "}
              </h4>
              <h4 className="font-bold">
                Land Size <br />{" "}
                <span>{`${farmsDetails?.land_size} ${
                  parseInt(farmsDetails?.land_size, 10) > 0 ? "Acres" : "Acre"
                }`}</span>{" "}
              </h4>
              <h4 className="font-bold">
                Farm District <br /> <span>{farmsDetails?.district}</span>{" "}
              </h4>
              <h4 className="font-bold">
                Town <br /> <span>{farmsDetails?.town}</span>{" "}
              </h4>
            </div>

            <div className="cycle_counter_cont">
              <div className="cycle_counter">
                <div
                  className="counter_decre"
                  onClick={() => cycleNum !== 1 && setCycleNum(cycleNum - 1)}>
                  <MdArrowBackIosNew />
                </div>
                <div className="counter">
                  <h3>{cycleNum}</h3>
                </div>
                <div
                  className="counter_incre"
                  onClick={() =>
                    cycleNum < farmsCount && setCycleNum(cycleNum + 1)
                  }>
                  <MdArrowForwardIos />
                </div>
              </div>
              <h4>CYCLE NUMBER</h4>
            </div>
          </div>

          <div className="container_right_bottom">
            <div className="wallet" onClick={() => navigate("wallet")}>
              <h4 className="wallet_icon"> $</h4>
              <h4>Wallet</h4>
            </div>

            <div className="analytics" onClick={() => {}}>
              <MdOutlineAnalytics className="analytics_icon" />
              <h4>Analytics</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmerDashboard;
