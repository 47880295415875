import { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading2 } from '../../components/loading';
import { FarmerWalletInterface } from '../../db/interfaces';
import '../../styles/farmer_wallet.css'


const FarmerWallet =  ()=>{

    const navigation = useNavigate();
    const params = useParams();
    const [farmerWallet,setFarmerWallet] = useState<FarmerWalletInterface | null>(null);
    const [pageLoading,setPageLoading] = useState<boolean>(false);


    useEffect(()=>{
        const farmerId = params.id;

        //fetch farmer wallet
        if(farmerId){
            const farmer_wallet:FarmerWalletInterface = {
                accountBalance : 0,
                creditBalance : 0,
                totalOuputCost : 0,
                totalOutput : 0,
                totalServiceCost : 0
            }

            setTimeout(()=>{
                setFarmerWallet(farmer_wallet);
                setPageLoading(false)
            },300)
        }


    },[params])



    if(pageLoading) return <Loading2/>
    // if(!farmerWallet) return <div className='wallet_error'><h1>Error</h1></div>






    return(
        <div className="farmer_wallet" >

            <div className="close_container">
                <div className="close_btn"  onClick={()=>navigation(-1)}>
                    <MdArrowBack/>
                </div>
            </div>

            <div className='farmer_wallet_cont' data-aos="fade-up">

                <h4 className='farmer_wallet_title'>Wallet</h4>

                <h2>
                    Account Balance:
                    <span> GHC{farmerWallet?.accountBalance}</span>
                </h2>

                <h4>Total Ouput: <span>GHC{farmerWallet?.totalOutput}</span></h4>

                <h2>
                    Credit Balance:
                    <span> GHC{farmerWallet?.creditBalance}</span>
                </h2>

                <h4>Total Ouput Cost: <span> GHC{farmerWallet?.totalOuputCost}</span></h4>
                <h4>Total Service Cost: <span> GHC{farmerWallet?.totalServiceCost}</span></h4>
                <h4>Insurance Premium: <span> GHC{0}</span></h4>

                <div className='wallet_btn_cont'>
                    <button className='btn' onClick={()=> navigation('transactions')}>Transactions</button>
                </div>

            </div>
        </div>
    )
}


export default FarmerWallet;
