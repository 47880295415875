import React, { useState, useEffect } from 'react'
import { MdAdd, MdArrowBack, MdListAlt, MdOutlineGridView } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import '../../styles/warehouse_details.css'
import '../../styles/farmers.css'
import { HiOutlinePlusSm } from 'react-icons/hi'





interface InventoryProfile {
  id : string;
  name : string;
  inventoryType : string;
  quantity : string;
  imgUrl? : string;
  description? : string
}

const TableTile = ({id, name, inventoryType, quantity} : InventoryProfile)=>{
  const navigate = useNavigate();

  return(
    <tr className="table_tile table-row"  onClick={()=>navigate(id)} >
      <td>{name}</td>
      <td>{inventoryType}</td>
      <td>{quantity}</td>
    </tr>
  )
}


const Card  = ({id , name, inventoryType,description, quantity}:InventoryProfile)=>{
  return (
    <div className='inventory_card'>
      <h4>{name}</h4>
      <img src="https://th.bing.com/th/id/OIP.E8Fn0J887VVzgkvFsqavvAHaE7?pid=ImgDet&rs=1" alt="" />
      <h5>{description}</h5>
      <div className='inventory_card__actions'>
        <div>
          <div className='inventory_card_btn'>
            -
          </div>
          <div className='inventory_quantity'>
            {quantity}KG
          </div>
          <div className='inventory_card_btn'>
            <MdAdd/>
          </div>
        </div>
      </div>

    </div>
  )
}




const WarehouseDetails = () => {
  const navigation = useNavigate();
  const [inventories, setInventories] = useState<InventoryProfile[]>([]);
  const [listView, setListView] = useState(false);

  useEffect(()=>{
    setInventories([
      {name : "Maize", id : "23", inventoryType : "INPUT", quantity : "0", description :"This si the description for the maize. hope you liked it"},
      {name : "Maize", id : "23", inventoryType : "PRODUCE", quantity : "0", description :"This si the description for the maize. hope you liked it"},
      {name : "Maize", id : "23", inventoryType : "PRODUCE", quantity : "0", description :"This si the description for the maize. hope you liked it"},
      {name : "Maize", id : "23", inventoryType : "INPUT", quantity : "0", description :"This si the description for the maize. hope you liked it"},
    ])
  },[])


  return (
    <div className='c'>
      {/* go back button container */}
      <div className="close_container">
          <div className="close_btn"  onClick={()=>navigation(-1)}>
              <MdArrowBack/>
          </div>
      </div>

      <div className='actions_cont'>
        <div className='actions_cont_inner'>
          <div className='actions_btn_cont'>
            <div className='action_btn' title='list view' onClick={()=> setListView(true)}>
              <MdListAlt/>
            </div>
            <div title='grid view' className='action_btn' onClick={()=> setListView(false)}>
              <MdOutlineGridView/>
            </div>

            <div className='action_btn' title='add farmer' onClick={()=>navigation("/inventoryadd")} >
                  <HiOutlinePlusSm title='add inventory'/>
              </div>
          </div>

        </div>
      </div>

      <div className='warehouse_details_title'>
        <h1>Storage One</h1>
        <h4>Add and romeve item from the storage </h4>
      </div>


        {
          !listView ?
          <div className='warehouse_details_grid'>
            {
              inventories.map((inventory,i)=>{
                return (
                  <Card key={i} description = {inventory?.description} id ={inventory.id} quantity = {inventory.quantity} name = {inventory.name} inventoryType = {inventory.inventoryType} />
                )
              })
            }
          </div>
          :
          <div className='list_farmers_cont'>
            <table>
                <thead className='table_head'>
                    <tr className='table-row'>
                        <th>NAME</th>
                        <th>INVENTORY TYPE</th>
                        <th>QUANTITY</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        inventories.map((warehouse,i)=>{
                            const {name,id , inventoryType, quantity} = warehouse;
                            return(
                                <TableTile key={i} id={id} name={name} inventoryType = {inventoryType} quantity = {quantity} />
                            )
                        })
                    }

                </tbody>
            </table>
          </div>
        }
    </div>
  )
}

export default WarehouseDetails
