import {
  clearCachedUser,
  getCachedUser,
} from "../../service/common";
import { GetAdminDetails } from "../apis/auth.service";
import { adminLoginActions } from "../slice/auth/adminLoginSlice";
import { STORE_ACTION_TYPES } from "../types";

export const requestAdminDetails = async (dispatch: any) => {
  dispatch(adminLoginActions[STORE_ACTION_TYPES.AUTH.REQUEST]({}));
  const userData = getCachedUser(dispatch);

  const response = await GetAdminDetails(userData?.id);

  if (response.data?.code !== 200) {
    return;
  }
  dispatch(
    adminLoginActions[STORE_ACTION_TYPES.AUTH.RESPONSE]({
      user: response.data?.msg,
    })
  );
};

export const adminLogout = (dispatch: any) => {
  dispatch(adminLoginActions[STORE_ACTION_TYPES.AUTH.LOGOUT]({}));
  clearCachedUser();
  window.location.pathname = "/";
};
