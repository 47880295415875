import { MdOutlineClose } from "react-icons/md";
import ServiceAddForm from "./service_add-form";

interface ModalInfo {
  closeModal: Function;
}

const ServiceAddModal = ({ closeModal }: ModalInfo) => {
  return (
    <div className="w-[23rem] bg-white">
      <div id="header" className="bg-[#37847E] py-4 px-8">
        <div className="flex justify-between items-center">
          <div className="text-white font-semibold text-xl">Add Service</div>
          <button
            className="block text-white font-semibold text-3xl cursor-pointer"
            onClick={() => closeModal(false)}>
            <MdOutlineClose />
          </button>
        </div>
        <p className="text-[#C4C4C4] text-sm w-[80%]">
          Provide information about your Service
        </p>
      </div>
      <div id="form" className="py-5 px-8">
        <ServiceAddForm />
      </div>
    </div>
  );
};

export default ServiceAddModal;
