import { FieldAgentProfile, GlobalState, PayLoad } from "../db/interfaces";
import { 
    ADD_FARMERS, ADD_FIELD_AGENTS, DELETE_FARMER, DELETE_FARMERS, 
    DELETE_FIELD_AGENT, LOGIN, LOGOUT, SET_DARK_THEME, 
    SET_LIGHT_THEME, UPDATE_FARMERS, UPDATE_FIELD_AGENT 
} from "./constants";





export const initialState : GlobalState  = {
    user : null,
    theme : "light",
    farmersQuery : null,
    fieldAgentsQuery : null,
    groupsQuery : null
}


export const reducer = (state : any , actions : PayLoad) => {



    
    switch(actions.type){
        // FOR AUTHENTICATION
        case LOGIN:
            return {
                ...state,
                user : actions.payload
            }
        
        case LOGOUT:
            return {
                ...state,
                user : null
            }

        // FOR THEME CONFIGURATION
        case SET_DARK_THEME:
            return {
                ...state,
                theme : "dark"
            }

        case SET_LIGHT_THEME:
            return {
                ...state,
                theme : "light"
            }



        //FOR FARMERS
            //FOR ADDING FARMERS
        case ADD_FARMERS:
            
            return {
                ...state,
                farmersQuery : actions.payload
            }

            
            //FOR UPDATING FARMER
        case UPDATE_FARMERS:
            

            return {
                ...state,
                listFarmers : [
                ]
            }

            //FOR DELETING FARMERS
        case DELETE_FARMERS:
            return {
                ...state,
                farmersQuery : null
            }


            //FOR DELETING A FARMER
        case DELETE_FARMER:
            let farmerToBeDeleteId  = actions.payload;
            return {
                ...state,
                farmersQuery : {
                    ...state.farmersQuery,
                    total : (parseInt(state.farmersQuery.total) - 1).toString(),
                    data : state.farmersQuery.data?.filter((farmer:any)=> farmer.id !== farmerToBeDeleteId)
                }
            }



        //FOR FIELD AGENTS
            //FOR ADDING FIELD_AGENTS
        case ADD_FIELD_AGENTS:
            return {
                ...state,
                fieldAgentsQuery : actions.payload
            }

            
            //FOR UPDATING FIELD AGENT
        case UPDATE_FIELD_AGENT:

            let fieldAgent = actions.payload;
            let fieldAgentId = fieldAgent.id;

            return {
                ...state,
                listFieldAgents : state.listFieldAgents && [
                    ...(
                        state.listFieldAgents?.map((_fieldAgent : FieldAgentProfile)=>{
                            if(_fieldAgent.id === fieldAgentId){
                                return {...fieldAgent}
                            }
                            else{
                                return {..._fieldAgent}
                            }
                        })
                    )
                ]
            }

            //FOR DELETING FIELD AGENT
        case DELETE_FIELD_AGENT:
            return {
                ...state,
                listFieldAgents : null
            }

        // DEFAULT CONFIGURATION  ---  NO CHANGES TO BE MADE
        default:
            return { ...state }
    }
}