import { asp_data } from "../db/asp_data";
const Demarcation = () => {
  return (
    <section data-aos="fade-right" className="mx-9">
      <h2 className="font-bold text-xl mb-3">ASP</h2>
      <ul className="flex flex-col gap-3">
        {asp_data.map((data, index) => (
          <li key={index} className="flex gap-2 items-center">
            <div
              className="color w-3 h-3 bg-[${data.color}]"
              style={{ backgroundColor: data.color }}></div>
            <p className="text-[#C4C4C4] text-[15px]">{data.text}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Demarcation;
