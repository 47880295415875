import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";

import { CreateClient } from "../apis/client.service";
import { addClientActions } from "../slice/client/addClientSlice";
import { STORE_ACTION_TYPES } from "../types";

interface Clients {
  id: string;
  name: string;
  email: string;
  country: string;
  state: string;
  city: string;
  logo: string;
  admins_count: number;
  agents_count: number;
  farmers_count: number;
  farmers: any[];
  admins: any[];
  agents: any[];
}

interface getClientParams {
  adminId: string;
  page?: string;
}

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (adminId: any, page?: any,) => {
    try {
      const response = await getAxios(BACKEND_BASE_URL).post(
        `/company?${page ? page : ""}`,
        {
          admin_id: adminId,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createClient = async (dispatch: any, clientData: any) => {
  dispatch(addClientActions[STORE_ACTION_TYPES.ADD_CLIENT.SEND_INFO]({}));
  try {
    await CreateClient(clientData);
    // const { code, msg } = response.data;

    // if (code !== 200) {
    //   console.log(msg);
    //   dispatch(addClientActions[STORE_ACTION_TYPES.ADD_CLIENT.FAILED](msg));
    // }

    dispatch(addClientActions[STORE_ACTION_TYPES.ADD_CLIENT.RESPONSE](true));
  } catch {
    dispatch(
      addClientActions[STORE_ACTION_TYPES.ADD_CLIENT.FAILED](
        "Failed to add client"
      )
    );
  }
};
