import { GetFieldAgents } from "../apis/fieldAgents.service"
import { fieldAgentsActions } from "../slice/fieldAgents/fieldAgentsSlice"
import { STORE_ACTION_TYPES } from "../types"


export const requestFieldAgents = async(dispatch: any, companyId: string)=>{
    dispatch(fieldAgentsActions[STORE_ACTION_TYPES.FIELD_AGENTS.REQUEST]({}))
    try{
        const response = await GetFieldAgents(companyId);
        const {msg, code} = response.data
        if(code !== 200){
            dispatch(fieldAgentsActions[STORE_ACTION_TYPES.FIELD_AGENTS.FAILED]('Failed to fetch FIELD_AGENTS'))
        }

        dispatch(fieldAgentsActions[STORE_ACTION_TYPES.FIELD_AGENTS.RESPONSE](msg))

    }
    catch{
        dispatch(fieldAgentsActions[STORE_ACTION_TYPES.FIELD_AGENTS.FAILED]('Failed to fetch farmers'))

    }

}
