import React, { useEffect, useState } from "react";
import { GetCountries } from "../../redux/apis/helpers.service";

const ServiceReqForm = () => {
  const [formData, setFormData] = useState({
    country: "",
    serviceType: "",
    landSize: "",
    date: "",
    description: "",
  });

  const [countries, setCountries] = useState<
    { name: string; id: number }[] | null
  >(null);

  useEffect(() => {
    GetCountries().then((response: any) => {
      const { code, msg } = response.data;
      if (code !== 200) return;
      setCountries(msg);
    });
  }, []);

  // const countries = ['Country A', 'Country B', 'Country C']; // Replace with your list of countries
  const serviceTypes = ["Service Type 1", "Service Type 2", "Service Type 3"]; // Replace with your list of service types

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Handle form submission logic here

    console.log("Form submitted:", formData);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-5">
      <div className="md:flex gap-5">
        <div className="flex flex-col gap-2 md:w-[40%]">
          <label
            htmlFor="serviceTypeSelect"
            className="text-[#C4C4C4] text-sm block">
            Service Type
          </label>
          <div className="border-[2px] text-sm rounded-lg  pr-3 hover:border-[#37847E]">
            <select
              className="w-full h-full py-2 cursor-pointer rounded-lg"
              id="serviceTypeSelect"
              name="serviceType"
              value={formData.serviceType}
              required
              onChange={handleInputChange}>
              <option value="" className="mr-4">
                Select Service
              </option>
              {serviceTypes.map((serviceType) => (
                <option key={serviceType} value={serviceType}>
                  {serviceType}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex flex-col gap-2 md:w-[60%]">
          <label
            htmlFor="countrySelect"
            className="text-[#C4C4C4] text-sm block">
            Country
          </label>
          <div className="border-[2px] text-sm rounded-lg  pr-3 hover:border-[#37847E]">
            <select
              className="w-full h-full py-2 cursor-pointer rounded-lg"
              id="countrySelect"
              name="country"
              value={formData.country}
              required
              onChange={handleInputChange}>
              <option value="">Select a Country</option>
              {countries?.map((country) => (
                <option key={country?.id} value={country?.id}>
                  {country?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="md:flex gap-5">
        <div className="flex flex-col gap-2 md:w-[40%]">
          <label
            htmlFor="serviceTypeSelect"
            className="text-[#C4C4C4] text-sm block">
            Land Size
          </label>
          <div className="border-[2px] text-sm rounded-lg  pr-3 flex items-center hover:border-[#37847E]">
            <input
              type="text"
              className="w-full h-full px-1 py-2 cursor-pointer rounded-lg"
              id="landSizeSelect"
              name="landSize"
              placeholder="Size"
              value={formData.landSize}
              required
              onChange={handleInputChange}></input>
            <span className="block text-[#C4C4C4] text-sm">Yard</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 md:w-[60%]">
          <label
            htmlFor="countrySelect"
            className="text-[#C4C4C4] text-sm block">
            Service Starting date
          </label>
          <div className="border-[2px] text-sm rounded-lg hover:border-[#37847E]">
            <input
              type="date"
              className="w-full h-full py-2 cursor-pointer rounded-lg px-3"
              id="countrySelect"
              name="date"
              value={formData.date}
              required
              onChange={handleInputChange}></input>
          </div>
        </div>
      </div>

      <div>
        <label htmlFor="description" className="text-[#C4C4C4] text-sm block">
          Service Description
        </label>
        <div className="border-[2px] text-base rounded-lg w-full h-28">
          <textarea
            name="description"
            placeholder="Describe the service"
            value={formData.description}
            required
            onChange={handleInputChange}
            className=" rounded-lg p-3 w-full h-full resize-none outline-none"
          />
        </div>
      </div>

      <div className="sub_btn rounded-lg text-sm bg-[#37847E] text-white cursor-pointer border-[2px] border-[#37847E] hover:font-medium hover:bg-[#2a6b66] delay-100 w-[80%] md:w-[50%] h-fit">
        <button type="submit" className=" h-full m-0 py-2 px-8">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ServiceReqForm;
