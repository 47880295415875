import axios from "axios";
import { APP_KEY, ADMIN_TOKEN } from "../state_manager/constants";
// import { jwtDecode } from "jwt-decode";
import { adminLoginActions } from "../redux/slice/auth/adminLoginSlice";
import { STORE_ACTION_TYPES } from "../redux/types";

let timeoutTimer: ReturnType<typeof setTimeout>;

// Function to logout the user and clear the session
const logoutAndClearSession = (dispatch: any) => {
  dispatch(adminLoginActions[STORE_ACTION_TYPES.AUTH.EXPIRED](true));
};

// Function to start the session timeout countdown
export const startSessionTimeout = (dispatch: any) => {
  clearTimeout(timeoutTimer);
  timeoutTimer = setTimeout(() => logoutAndClearSession(dispatch), 120 * 60 * 1000); // 30 minutes in milliseconds  (adjust to desired time)
};

// export const getAxios = (baseURL: string = "") => {
//   const axiosInstance = axios.create({ baseURL });
//   let token = getCachedToken()?.token;
//   if (token) {
//     axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   }

//   axiosInstance.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//       if (error?.response?.status === 401) {
//         clearCachedUser();
//         // window.location.reload();
//       }
//       return Promise.reject(error);
//     }
//   );
//   return axiosInstance;
// };

// Axios instance with interceptors for handling token and session timeout
export const getAxios = (baseURL: string = "", dispatch?: any) => {
  const axiosInstance = axios.create({ baseURL });
  let token = getCachedToken()?.token;
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  axiosInstance.interceptors.response.use(
    (response) => {
      if (dispatch) {
        startSessionTimeout(dispatch); // Reset session timeout on successful requests
      }
      return response;
    },
    (error) => {
      if (dispatch && error?.response?.status === 401) {
        logoutAndClearSession(dispatch); // Logout on 401 Unauthorized error
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};


export const cacheToken = (
  token: string,
  expires_in: number,
  remember_me: boolean
) => {
  remember_me
    ? localStorage.setItem(
        ADMIN_TOKEN,
        JSON.stringify({ token, expires_in })
      )
    : sessionStorage.setItem(
        ADMIN_TOKEN,
        JSON.stringify({ token, expires_in })
      );
};

// Function to cache user data with expiration time
export const cacheUser = (userData: any, remember_me: boolean, dispatch: any) => {
  const expiration = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours
  const data = {
    id: userData.id,
    expiration,
  };
  remember_me
    ? localStorage.setItem(`${APP_KEY}_user`, JSON.stringify(data))
    : sessionStorage.setItem(`${APP_KEY}_user`, JSON.stringify(data));

  startSessionTimeout(dispatch); // Start session timeout countdown after caching user data
};

// Function to get cached token
export const getCachedToken = () => {
  const token =
    sessionStorage.getItem(ADMIN_TOKEN) || localStorage.getItem(ADMIN_TOKEN);
  if (token) {
    return JSON.parse(token);
  } else {
    return undefined;
  }
};

/// Function to get cached user data and check for expiration
// export const getCachedUser = (dispatch: any) => {
//   const storedData =
//     sessionStorage.getItem(`${APP_KEY}_user`) ||
//     localStorage.getItem(`${APP_KEY}_user`);
//   if (storedData) {

//     const data = JSON.parse(storedData);
//     if (data.expiration) {
//       // Compare expiration time in milliseconds
//       if (new Date().getTime() < data.expiration) {
//         // User is still authenticated
//         return data;
//       } else {
//         // Update auth expired status
//         dispatch(adminLoginActions[STORE_ACTION_TYPES.AUTH.EXPIRED](true));
//         return data;
//       }
//     }
//   }
//   return null; // User needs to log in again
// };



// Function to get cached user data and check for expiration
export const getCachedUser = (dispatch: any) => {
  const storedData =
    sessionStorage.getItem(`${APP_KEY}_user`) ||
    localStorage.getItem(`${APP_KEY}_user`);
  if (storedData) {
    const data = JSON.parse(storedData);
    if (data.expiration && new Date().getTime() < data.expiration) {
      startSessionTimeout(dispatch); // Start session timeout countdown if user is still authenticated
      return data;
    } else {
      logoutAndClearSession(dispatch); // Logout if user session has expired
      return data;
    }
  }
  return null; // User needs to log in again
};

// Function to clear cached user data and session
export const clearCachedUser = () => {
  localStorage.removeItem(`${APP_KEY}_user`);
  sessionStorage.clear();
};
