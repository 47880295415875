import "../styles/dashboards.css";
import { Outlet } from "react-router-dom";
import SideNavbar from "../components/side_navbar";
import Map from "../components/map";
import Weather from "../components/weather";
import Demarcation from "../components/demarcation";
import { useState } from "react";
import { StringDecoder } from "string_decoder";
import PersonImage from "../assets/images/person.png";
import { useSelector } from "react-redux";

const Dashboards = () => {
  const { is_super_admin }: { is_super_admin: number } = useSelector(
    (state: any) => state.admin.user
  );
  const [isDemarcationVisible, setDemarcationVisible] = useState(true);
  const [isWeatherVisible, setWeatherVisible] = useState(false);

  const toggleActive = (text: string) => {
    if (text === "Demarcation") {
      setDemarcationVisible(true);
      setWeatherVisible(false);
    } else {
      setDemarcationVisible(false);
      setWeatherVisible(true);
    }
  };

  return (
    <div className="dashboards w-full max-w m-auto p-3 md:p-28">
      <div className="w-full flex flex-col items-center justify-center">
      <div data-aos="fade-up" className="w-[95%] gap-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-5">
        <Card title="New Farmer" current_number="2.500M" />
        {is_super_admin === 1 && (
          <Card title="Client" current_number="2.500M" />
        )}
        <Card title="Field Agents" current_number="2.500M" />

      </div>
      <div
        className=" dashboards-cont h-full w-[95%] flex flex-col md:flex-row md:justify-between"
        data-aos="fade-up">
        {/* map section */}
        <div className="md:w-[75%] h-[75vh] md:h-screen ">
          <Map />
        </div>
        {/* Data set section */}
        <div className="md:w-[25%] m-5 ">
          <div className="menu flex flex-row gap-1 md:gap-0 flex-wrap">
            <h3
              className={`w-1/2 ${
                isDemarcationVisible && "bg-[#E1F1EA]"
              } text-center text-lg  hover:cursor-pointer py-3`}
              onClick={() => toggleActive("Demarcation")}>
              Demarcation
            </h3>
            <h3
              className={`w-1/2 ${
                isWeatherVisible && "bg-[#E1F1EA]"
              } text-center text-lg  hover:cursor-pointer py-3`}
              onClick={() => toggleActive("Weather")}>
              Weather
            </h3>
          </div>
          {/* Data set content section */}
          <div className="content_cont my-7 mx-1">
            {isDemarcationVisible && (
              <div>
                <Demarcation />
              </div>
            )}
            {isWeatherVisible && <Weather />}
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Dashboards;

const Card = ({
  title,
  current_number,
}: {
  title: string;
  current_number: string;
}) => {
  return (
    <div className="w-full bg-[var(--bg2)]  bg-white p-5 rounded-xl ">
      <div className="flex items-center">
        <img src={PersonImage} className="m-5" alt="" />
        <div>
          <h4 className="text-xl text-[#A5ABA3]">{title}</h4>
          <h4>{current_number}</h4>
        </div>
      </div>

      <div className="w-full flex justify-between gap-3 px-5">
        <div>
          <h4 className="text-[#A5ABA3] text-base">Previous</h4>
          <h4 className="text-sm font-medium mt-2">{`1.543M`}</h4>
        </div>
        <div>
          <h4 className="text-[#A5ABA3] text-base">%Change</h4>
          <h4 className="text-sm font-medium mt-2">{`+23.05%`}</h4>
        </div>
        <div>
          <h4 className="text-[red] text-base mt-2">Trends</h4>
        </div>
      </div>
    </div>
  );
};
