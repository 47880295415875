import {
  GetAllFarmers,
  GetFarmerDetails,
  GetFarmers,
  GetFarmerFarmDetails,
  DisplayFarmers,
} from "../apis/farmers.service";
import { farmersActions } from "../slice/farmers/farmersSlice";
import { STORE_ACTION_TYPES } from "../types";

export const requestFarmers = async (
  dispatch: any,
  adminId: number,
  company_id: number,
  paginate?: string
) => {
  paginate ?? dispatch(farmersActions[STORE_ACTION_TYPES.FARMERS.REQUEST](''));
  try {
    const response = await GetFarmers(company_id, adminId, paginate);

    const { msg, code } = response.data;

    if (code !== 200) {
      dispatch(
        farmersActions[STORE_ACTION_TYPES.FARMERS.FAILED](
          "Failed to fetch farmers"
        )
      );
    }

    dispatch(farmersActions[STORE_ACTION_TYPES.FARMERS.RESPONSE](msg));
  } catch {
    dispatch(
      farmersActions[STORE_ACTION_TYPES.FARMERS.FAILED](
        "Failed to fetch farmers"
      )
    );
  }
};
export const requestAllFarmers = async (adminId: number) => {
  try {
    const response = await GetAllFarmers(adminId);

    const { msg } = response.data;
    // console.log(msg);

    return msg;
  } catch (error) {
    console.error(error);
  }
};

export const requestDisplayFarmers = async () => {
  try {
    const response = await DisplayFarmers();

    const data = response.data;

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const requestFarmerDetails = async (
  dispatch: any,
  farmer_id: string,
  company_id: string
) => {
  try {
    const response = await GetFarmerDetails(farmer_id, company_id);
    const { msg, code } = response.data;
    if (code !== 200) {
      return;
    }
    dispatch(farmersActions[STORE_ACTION_TYPES.FARMERS.VIEW_FARMER](msg));
  } catch {
    dispatch(
      farmersActions[STORE_ACTION_TYPES.FARMERS.FAILED](
        "Failed to fetch farmer detail"
      )
    );
  }
};
export const requestFarmerFarmDetails = async (
  dispatch: any,
  farmer_uid: string
) => {
  try {
    const response = await GetFarmerFarmDetails(farmer_uid);
    const { msg, code } = response.data;
    if (code !== 200) {
      return;
    }
    dispatch(farmersActions[STORE_ACTION_TYPES.FARMERS.VIEW_FARMER_FARM](msg));
  } catch {
    dispatch(
      farmersActions[STORE_ACTION_TYPES.FARMERS.FAILED](
        "Failed to fetch farmer farm detail"
      )
    );
  }
};

// const delteFarmer = async(farmerId : String)=>{

//     if(window.confirm("Are you sure you want to delete farmer?") === false) return;

//     const token =  getCachedToken();
//     await axiosInstance.post("/admin/api/farmer/delete",{farmer_id : farmerId},{headers : {"Authorization" : `Bearer ${token}`}})
//     .then((res:any)=>{
//         console.log("res for delete", res.data)
//         const {code, msg} = res.data;
//         if(code === 200) {
//             dispatch({type : DELETE_FARMER , payload  : farmerId});
//             toast(msg)
//             //go back to the dashboard
//             navigate(-1);
//         }
//         else{
//             toast(msg)
//         }
//     })
//     .catch((err:any)=>{alert(err.message)})
// }
