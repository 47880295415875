import React, { useState } from "react";
import { FiUser } from "react-icons/fi";
import { RiGroupLine } from "react-icons/ri";
import FarmerCycle from "./farmer_cycle";
import GroupCycle from "./group_cycle";

const Tab = ({ children, icons }: any) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="">
      <div className="flex">
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            onClick={() => handleTabClick(index)}
            className={`font-medium focus:outline-none text-lg flex items-center gap-4 py-2 px-4 border-b-2  ${
              activeTab === index
                ? " border-b-primary text-primary"
                : "border-transparent"
            } ${index === 0 ? "" : ""}`}
          >
            {icons[index]}
            <button>{child.props.title}</button>
          </div>
        ))}
      </div>
      <div className="py-8">{React.Children.toArray(children)[activeTab]}</div>
    </div>
  );
};

const TabPanel = ({ children }: any) => {
  return <>{children}</>;
};

const CycleTabs = () => {
  const icons = [<FiUser />, <RiGroupLine />];

  return (
    <div className="pb-8">
      <Tab icons={icons}>
        <TabPanel title="Group Cycle">
          <GroupCycle />
        </TabPanel>
        <TabPanel title="Farmer Cycle">
          <FarmerCycle />
        </TabPanel>
      </Tab>
    </div>
  );
};

export default CycleTabs;
