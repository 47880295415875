import { SET_DARK_THEME, SET_LIGHT_THEME } from "../state_manager/constants";
import { setTheme } from "./general";

export const toggleDarkMode = (theme : "dark" | "light", dispatch : any)=>{

    if(theme === "light"){
        setTheme("dark");
        dispatch({type : SET_DARK_THEME});
    }else{
        setTheme("light");
        dispatch({type : SET_LIGHT_THEME});
    }

    

}


export const toggleSideNavbar = ()=>{
    const sideNavbar = document.querySelector(".side_navbar");
    sideNavbar && sideNavbar.classList.toggle("side_navbar_active");
}
