import { MdArrowBack, MdOutlineSearch} from "react-icons/md"
import { useNavigate } from "react-router-dom"
import '../../styles/warehouse_transactions.css'



interface Product{
    name : string,
    brand : string,
    quantity : number,
    weight : number,
    price : number,
    date : string
}


const TransactionTile = ({name, quantity, brand,price ,date} : Product )=>{
    const colours = {
        violet : "CDAFE3",
        yellow : "FBFD86",
        green : "0FAC9C"
    }

    const useColour : ()=> string = ()=>{
        return colours.yellow
    }

    return(
        <div className="transaction_tile"  style={{backgroundColor : `${useColour()} !important` }}>
            <h4>{name}</h4>
            <h4>{brand}</h4>
            <h4>{quantity} Bags</h4>
            <h4>{price}</h4>
            <h4>{date}</h4>
        </div>
    )
}

const WarehouseTransactions = ()=>{
    
    const navigation = useNavigate()

    return (
        <div className="warehouse_transactions">

           <div className="warehouse_transactions_inner">

                <div className="close_container">
                    <div className="close_btn"  onClick={()=>navigation(-1)}>
                        <MdArrowBack/>
                    </div>
                </div>

                <h4 className="transactions_title">Transaction Records</h4>


                <h4 className="warehouse_name">{'Warehouse Name'}</h4>

                <div className="type_transaction_indicators_cont" >
                    <div className="type_transaction_indicators">

                        <div className="type_transaction_indicator">
                            <div className="indicator green"></div>
                            <h4>Received</h4>
                        </div>
                        <div className="type_transaction_indicator">
                            <div className="indicator violet"></div>
                            <h4>Disbursed</h4>
                        </div>

                    </div>
                </div>



                <div className="transaction_search" data-aos = "fade-up">
                    <div className="transaction_search_field"> 
                        <MdOutlineSearch/>
                        <input type="text" placeholder="search transaction" />
                    </div>

                </div>


                <div className="list_transactions" data-aos = "fade-up" data-aos-delay = "100">
                    <div className="transaction_table_head">
                        <h4>ITEM</h4>
                        <h4>TYPE</h4>

                        <h4>QUANTITY</h4>
                        <h4>PRICE</h4>
                        <h4>DATE</h4>

                    </div>



                    <TransactionTile name="Maize" brand="SWEET" quantity={100} date = "30/2/2022" 
                    price={300} weight = {0} />

                    <TransactionTile name="Tomatoes" brand="LESCO" quantity={100} date = "-" 
                    price={300} weight = {70} />

                    <TransactionTile name="Maize" brand="LESCO" quantity={100} date = "-" 
                    price={300} weight = {70} />


                    <TransactionTile name="Rice" brand="LESCO" quantity={100} date = "12/09/2021" 
                    price={300} weight = {70} />

                    <TransactionTile name="Fertilizer" brand="LESCO" quantity={100} date = "02/03/2022" 
                    price={300} weight = {70} />

                    <TransactionTile name="Millet" brand="LESCO" quantity={100} date = "--" 
                    price={300} weight = {70} />
                </div>



                <div className="back_to_top">
                    <h4>Back to top</h4>
                </div>

           </div>
        </div>
    )
}


export default WarehouseTransactions