import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";

// export const GetCycle = async (admin_id: number, page: any) =>
//   await getAxios(BACKEND_BASE_URL).post(`/company?${page ? page : ''}`, {admin_id});


export const CreateCycle = async (cycle_data: any)=>
  await getAxios(BACKEND_BASE_URL).post(`/company/cycles/store`,cycle_data)

