import React, { useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { axiosInstance } from '../../redux/apis/axios';
import { ADMIN_TOKEN } from '../../state_manager/constants';
import FormInput from '../../components/form_input';
import { getCachedToken } from '../../service/common';

const WarehouseAdd = () => {

    const navigation = useNavigate();

    const [formData,setFormData] = useState(
        {
            name :"",
            region: "",
            district: "",
            inventory_type : ""
        }
    );


    const handleSubmit = async()=>{
        const token =  getCachedToken();
        console.log(formData)
        const tempData = { name : formData.name, region : parseInt(formData.region), district : parseInt(formData.district), inventory_type : formData.inventory_type}
        await axiosInstance.post("/admin/api/company/warehouse/store", tempData, {headers : {"Authorization" : `Bearer ${token}`}} )
        .then(res => {
            const {msg,code} = res.data;
            if(code !== 200){
                toast(msg,{toastId : "warehouseToast"})
            }

            if(res.data === 200){
                // console.log(res.data)
            }
        })
        .catch(err => {toast("Couldn't add warehouse")  ;console.log(err) })

    }


  return (
    <div className='warehouse_add'>
        <div className="close_container">
            <div className="close_btn"  onClick={()=>navigation(-1)}>
                <MdArrowBack/>
            </div>
        </div>




        <div style={{width : "100%", maxWidth : "600px", margin : "auto",marginTop : "50px", backgroundColor : 'var(--white)', padding : '30px 20px', borderRadius : '15px'}}>
            <div style={{width : "100%", maxWidth : "1200px", marginBottom : "30px"}}>
                <h1 className="font-bold text-2xl">Warehouse Setup</h1>
            </div>
            <FormInput title="Warehouse Name" name="name" type="text" setValue = {setFormData} data = {formData}  />
            <FormInput title="Region" name="region" type="text" setValue = {setFormData} data = {formData}  />
            <FormInput title="District" name="district" type="text" setValue = {setFormData} data = {formData}  />
            <FormInput title="Inventory Type" name="inventory_type" selectValues={["Produce Warehouse", "Input Warehouse", "Input & Produce Warehouse"]}  isSelect setValue = {setFormData} data = {formData} />

            <button className='btn' onClick={handleSubmit} style={{marginTop : "30px"}}>
                Continue
            </button>
        </div>

    </div>
  )
}

export default WarehouseAdd
