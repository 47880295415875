import { useSelector } from "react-redux";
import { HiOutlineRefresh } from "react-icons/hi";
import {
  MdCircle,
  MdHelpOutline,
  MdOutlineHouse,
  MdOutlineLogout,
  MdOutlineSettings,
  MdPeopleOutline,
  MdPersonOutline,
  MdOutlineDashboard,
} from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { toggleDarkMode } from "../functions/navbar";
import { LOGOUT } from "../state_manager/constants";
import { useStateValue } from "../state_manager/contextApi";
import "../styles/side_navbar.css";

let NavigationTile = ({
  Icon,
  navText,
  url,
}: {
  Icon: any;
  navText: string;
  url: string;
}) => {
  let resolved = useResolvedPath(url);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link
      to={url}
      className={`side_navbar_tile  ${match && "side_navbar_tile_active"}`}>
      <div className="side_navbar_icon">
        <Icon />
      </div>
      <h4 className="side_navbar_title">{navText}</h4>
    </Link>
  );
};

// let NavigationTile2 = ({ Icon , navText,url }:{Icon : any , navText : string , url : string })=>{
//     let resolved = useResolvedPath(url);
//     let match = useMatch({ path: resolved.pathname, end: false });
//     return(
//         <Link to = {url} className={`side_navbar_tile  ${match && 'side_navbar_tile_active'}`}>
//             <div className="side_navbar_icon">
//                 <Icon/>
//             </div>
//             <h4 className="side_navbar_title">
//                 {navText}
//             </h4>
//         </Link>
//     )
// }

const SideNavbar = () => {
  const { is_super_admin }: { is_super_admin: number } = useSelector(
    (state: any) => state.admin.user
  );

  const {
    state: { theme },
    dispatch,
  } = useStateValue();

  const logout = (e: any) => {
    e.preventDefault();
    dispatch({ type: LOGOUT });
  };

  return (
    <div className="side_navbar">
      <div className="side_navbar_inner">
        <NavigationTile navText="Dashboard" url="" Icon={MdOutlineDashboard} />
        <NavigationTile
          navText="Farmers"
          url="farmers"
          Icon={MdPeopleOutline}
        />
        <NavigationTile
          navText="Field Agents"
          url="fieldagents"
          Icon={MdPersonOutline}
        />
        {/* <NavigationTile
          navText="Services"
          url="services"
          Icon={RiCustomerService2Fill}
        /> */}
        <NavigationTile
          navText="Warehouse"
          url="warehouses"
          Icon={MdOutlineHouse}
        />

        {
          //this is for only the main admin (LbH)
          is_super_admin === 1 && (
            <NavigationTile navText="Clients" url="clients" Icon={MdCircle} />
          )
        }

        <NavigationTile Icon={MdPeopleOutline} navText="Groups" url="groups" />
        <NavigationTile Icon={HiOutlineRefresh} navText="Cycles" url="cycles" />

        <NavigationTile
          Icon={MdOutlineSettings}
          navText="Settings"
          url="settings"
        />
        <NavigationTile navText="Help" url="help" Icon={MdHelpOutline} />

        <div className="side_actions">
          <div>
            <h4 className="side_navbar_title">Theme</h4>
            <div
              className={`dark_mode_toggle_cont `}
              onClick={() => toggleDarkMode(theme, dispatch)}>
              <span>🌜</span>
              <span>🌞</span>
              <div
                className={`dark_mode_indicator ${
                  true ? "dark_mode_indicator_active" : ""
                }`}></div>
            </div>
          </div>

          <div>
            <h4 className="side_navbar_title">Logout</h4>
            <div className="logout_cont" onClick={logout}>
              <MdOutlineLogout />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
