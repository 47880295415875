import { createSlice } from "@reduxjs/toolkit";
import { getCountries, getStates,  Country } from "../../actions/countryState";

interface CountriesState {
  countries: Country[]; // Change from 'Country' to 'Country[]'
  states: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: CountriesState = {
  countries: [], // Change from 'Country' to an empty array '[]'
  states: {},
  isLoading: false,
  error: null,
};

const countriesStateSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCountries.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.error = null;
        state.countries = payload.msg;
      })
      .addCase(getCountries.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(getStates.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStates.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.error = null;
        state.states = payload.msg;
      })
      .addCase(getStates.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        state.error = payload.error;
      });
  },
});

export default countriesStateSlice.reducer;
