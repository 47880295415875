import { FarmerProfile } from "../db/interfaces";

export const convertFarmerQuery = (farmerQuery: any) => {
  const {
    data,
    current_page,
    first_page_url,
    last_page_url,
    next_page_url,
    prev_page_url,
    path,
    total,
    to,
    links,
    from,
  } = farmerQuery;
  return {
    farmers: data,
    meta: {
      current_page,
      first_page_url,
      last_page_url,
      next_page_url,
      prev_page_url,
      path,
      total,
      to,
      links,
      from,
    },
  };
};

export const convertFarmerData = (farmerData: any) => {
  let { farms } = farmerData;
  return {
    farmerName: farmerData.name,
    country: farmerData.country_id,
    digitalAddress: farmerData.digital_address,
    farmingYears: farmerData.farming_years,
    gender: farmerData.gender,
    district: farmerData.district,
    state: farmerData.state_id,
    imgUrl: farmerData.image,
    id: farmerData.id,
    uid: farmerData.farmer_uid,
    farmType: farmerData.farmType || farms ? farms[0]?.get_farm_type?.name : "",
    phoneNumber: farmerData.phone,
    town: farmerData.town,
    houseNumber: farmerData.house_number,
    nationalId: farmerData.national_id_card,
    // farmLocation : farmerData.location,
    region: farmerData.region,
    dob: farmerData.dob,
    landSize: farmerData.land_size,
    farms: farms?.map((farm: any) => {
      return {
        id: farm.id,
        farmerId: farm.farmer_id,
        landSize: farm.land_size,
        district: farm.district,
        region: farm.region ? farm.region : "",
        // gps : farm.gps,
        town: farm.town,
        farmTypeId: farm.farm_type_id,
        stateId: farm.state_id,
      };
    }),
  };
};

export const convertFarmerForBackend = (farmerData: FarmerProfile) => {
  let { farms } = farmerData;

  return {
    name: farmerData.farmerName,
    farming_years: farmerData.farmingYears, //to be field with farming years
    national_id_card: farmerData.nationalId,
    digital_address: farmerData.digitalAddress, //digital address
    house_number: farmerData.houseNumber,
    phone: farmerData.phoneNumber,
    town: farmerData.town,
    gender: farmerData.gender,
    dob: farmerData.dob,
    image: farmerData.imgUrl,
    country_id: farmerData.country,
    land_size: farmerData.landSize,
    district: farmerData.district,
    state_id: farmerData.state,
    farmer_id: farmerData.id ? farmerData.id : null,
    admin_id: farmerData.adminId,
    company_id: farmerData.companyId,
    ...(farmerData.id && { id: farmerData.id }),
    farms: farms?.map((farm) => {
      return {
        farmtype: farm.farmTypeId,
        // state_id : farm.stateID,
        district: farm.district,
        state_id: farm.stateId,
        town: farm.town,
        land_size: farm.landSize,
        ...(farm.id && farm.id !== 0 && { id: farm.id }),
      };
    }),
  };
};

export const convertNewFarmerForBackend = (farmerData: FarmerProfile) => {
  let { farms, imgUrl } = farmerData;

  // Create FormData to send as multipart/form-data
  const formData = new FormData();

  // Append farmer details
  formData.append("name", farmerData.farmerName || "");
  formData.append("farming_years", farmerData.farmingYears?.toString() || "");
  formData.append("national_id_card", farmerData.nationalId || "");
  formData.append("digital_address", farmerData.digitalAddress || "");
  formData.append("house_number", farmerData.houseNumber || "");
  formData.append("phone", farmerData.phoneNumber || "");
  formData.append("town", farmerData.town || "");
  formData.append("gender", farmerData.gender || "");
  formData.append("dob", farmerData.dob || "");
  formData.append("country_id", farmerData.country?.toString() || "");
  formData.append("land_size", farmerData.landSize?.toString() || "");
  formData.append("district", farmerData.district || "");
  formData.append("state_id", farmerData.state || "");
  // formData.append("farmer_id", farmerData.id?.toString() || "");
  formData.append("admin_id", farmerData.adminId?.toString() || "");
  formData.append("company_id", farmerData.companyId?.toString() || "");
  if (farmerData.id) formData.append("id", farmerData.id);

  // Append farms details
  farms?.forEach((farm, index) => {
    formData.append(
      `farms[${index}][farmtype]`,
      farm.farmTypeId?.toString() || ""
    );
    formData.append(`farms[${index}][district]`, farm.district || "");
    formData.append(
      `farms[${index}][state_id]`,
      farm.stateId?.toString() || ""
    );
    formData.append(`farms[${index}][town]`, farm.town || "");
    formData.append(
      `farms[${index}][land_size]`,
      farm.landSize?.toString() || ""
    );
    if (farm.id) formData.append(`farms[${index}][id]`, farm.id.toString());
  });

  // Append image file if imgUrl is a File object
  if (imgUrl instanceof File) {
    formData.append("image", imgUrl, "image.jpg");
  } else if (typeof imgUrl === "string") {
    // Append imgUrl as string if it's a string
    formData.append("image", imgUrl);
  }

  return formData;
};
