import { createSlice } from "@reduxjs/toolkit";
import { fetchCycles } from "../../actions/cycles";

interface Cycle {
    id: string;
    name: string;
    variety: string;
    start_date: string;
    end_date: string;
  }

interface CyclesState {
  isLoading: boolean;
  error: null;
  failedMsg: string;
  meta: {};
  cycles: Cycle[],
  cycleDetail: { [code: string]: Cycle };
  cycleDetails: {},
}

const initialState: CyclesState = {
  isLoading: false,
  error: null,
  failedMsg: "",
  meta: {},
  cycles: [],
  cycleDetail: {},
  cycleDetails: {},
};

const cyclesSlice = createSlice({
  name: "cycles",
  initialState,
  reducers: {
    CycleDetails: (state, { payload }: any) => {
      const code = payload;
      const cycleDetail = state.cycleDetail[code];
      state.cycleDetails = cycleDetail;
      return;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCycles.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCycles.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;

        const {
          data,
          current_page,
          first_page_url,
          last_page_url,
          next_page_url,
          path,
          total,
          to,
          links,
        } = payload.msg;

        state.meta = {
          current_page,
          first_page_url,
          last_page_url,
          next_page_url,
          path,
          total,
          to,
          links,
        };

        state.cycles = data;

        state.cycleDetail = data.reduce(
          (acc: any, cycle: any) => ({
            ...acc,

            // cycle details
            [cycle.id]: {
              id: cycle.id,
              name: cycle.name,
              variety: cycle.variety,
              start_date: cycle.start_date,
              end_date: cycle.end_date,
              farmers: cycle.farmers
            },
          }),
          {}
        );
      })
      .addCase(fetchCycles.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        state.error = payload.msg;
      });
  },
});

export const cyclesActions = cyclesSlice.actions;
export default cyclesSlice.reducer;
