import {  createSlice } from '@reduxjs/toolkit';
import { STORE_ACTION_TYPES } from '../../types';

const initialState = {
  cycleLoading: false,
  failedMsg: '',
  success: false,
  cycleData: {
    is_group: 0,
    company_id: "",
    farmer_id: "",
    farm_type_id: 1,
    name : "",
    crop_type : "",
    variety: "",
    start_date: "",
    end_date: "",
  }
};

const addCycleSlice = createSlice({
  name: 'addCycle',
  initialState,
  reducers: {
    [STORE_ACTION_TYPES.ADD_CYCLE.UPDATE_INFO] : (state, action)=>{
        state.cycleLoading = false
        state.cycleData = {...(state.cycleData) ,...action.payload}
    },
    [STORE_ACTION_TYPES.ADD_CYCLE.SEND_INFO] : (state)=>{
        state.cycleLoading = true
    },
    [STORE_ACTION_TYPES.ADD_CYCLE.RESPONSE] : (state, action)=>{
        state.cycleLoading = false
        state.success = action.payload
        state.cycleData = initialState.cycleData
    },
    [STORE_ACTION_TYPES.ADD_CYCLE.FAILED] : (state, action)=>{
        state.cycleLoading = false
        state.failedMsg = action.payload
        state.success = false
    },


  }
});

export const addCycleActions = addCycleSlice.actions
export default addCycleSlice.reducer;