import {  createSlice } from '@reduxjs/toolkit';
import { STORE_ACTION_TYPES } from '../../types';

const initialState = {
  isLoading: false,
  failedMsg: '',
  success: false,
  clientData: {
    name : "",
    email : "",
    country_id: 0,
    state_id: 0,
    city: '',
    logo: ''
  }
};

const addClientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    [STORE_ACTION_TYPES.ADD_CLIENT.UPDATE_INFO] : (state, action)=>{
        state.isLoading = false
        state.clientData = {...(state.clientData) ,...action.payload}
    },
    [STORE_ACTION_TYPES.ADD_CLIENT.SEND_INFO] : (state)=>{
        state.isLoading = true
    },
    [STORE_ACTION_TYPES.ADD_CLIENT.RESPONSE] : (state, action)=>{
        state.isLoading = false
        state.success = action.payload
        state.clientData = initialState.clientData
    },
    [STORE_ACTION_TYPES.CLIENTS.FAILED] : (state, action)=>{
        state.isLoading = false
        state.failedMsg = action.payload
        state.success = false
    },


  }
});

export const addClientActions = addClientSlice.actions
export default addClientSlice.reducer;