const AUTH = {
  REQUEST: "AUTH_REQUEST",
  RESPONSE: "AUTH_RESPONSE",
  FAILED: "AUTH_FAILED",
  LOGOUT: "AUTH_LOGOUT",
  EXPIRED: "AUTH_EXPIRED",
};

const CLIENTS = {
  REQUEST: "REQUEST_CLIENTS",
  RESPONSE: "CLIENT_RESPONSE",
  FAILED: "CLIENTS_FETCH_FAILED",
};

const ADD_CLIENT = {
  UPDATE_INFO: "UPDATE_CLIENT_INFO",
  SEND_INFO: "SEND_CLIENT_INFO",
  RESPONSE: "SEND_CLIENT_INFO_RESPONSE",
  FAILED: "CLIENT_INFO_SEND_FAIL",
};

const ADD_CYCLE = {
  UPDATE_INFO: "UPDATE_CYCLE_INFO",
  SEND_INFO: "SEND_CYCLE_INFO",
  RESPONSE: "SEND_CYCLE_INFO_RESPONSE",
  FAILED: "CYCLE_INFO_SEND_FAIL",
};

const FARMERS = {
  REQUEST: "REQUEST_FARMERS",
  RESPONSE: "FARMERS_RESPONSE",
  FAILED: "FARMERS_FETCH_FAILED",
  VIEW_FARMER: "VIEW_FARMER_DATA",
  VIEW_FARMER_FARM: "VIEW_FARMER_FARM_DATA",
  CLEAR_FARMER: "CLEAR_FARMER_DATA",
};

const FIELD_AGENTS = {
  REQUEST: "REQUEST_FIELD_AGENTS",
  RESPONSE: "FIELD_AGENT_RESPONSE",
  FAILED: "FIELD_AGENTS_FETCH_FAILED",
  VIEW_FIELD_AGENT: "VIEW_FIELD_AGENT_DATA",
  CLEAR_FIELD_AGENT: "CLEAR_FIELD_AGENT_DATA",
};

const GROUPS = {
  REQUEST: "REQUEST_GROUPS",
  RESPONSE: "GROUPS_RESPONSE",
  FAILED: "GROUPS_FETCH_FAILED",
  VIEW_GROUP: "VIEW_GROUP_DATA",
  CLEAR_GROUPS: "CLEAR_GROUPS_DATA",
};

export const STORE_ACTION_TYPES = {
  AUTH,
  CLIENTS,
  ADD_CLIENT,
  ADD_CYCLE,
  FARMERS,
  FIELD_AGENTS,
  GROUPS
};
