import React, { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from 'react-router-dom'
import "../../styles/cycle_details.css";
import CycleTimer from "../../components/CycleTimer";
import { useSelector } from 'react-redux'
import { Avatar } from "@material-ui/core";

interface CycleDetail {
  farmGroup: string;
  cropType: string;
  startDate: string;
  endDate: string;
}

const TableTile = ({ id, name, image, index, handleClick }: any) => {
  const [isImageValid, setIsImageValid] = useState<boolean>(false);

  return (
    <tr
      className="table-row"
      onClick={() => handleClick(id)}>
      <td className="text-start">{index+1}</td>
      <td className="flex justify-start ">
        {isImageValid ? (
          <img
            src={`https://backend.lbhfarm.com/storage/${image}`}
            alt={name}
            onError={(e) => {
              setIsImageValid(!isImageValid);
            }}
          />
        ) : (
          <Avatar alt={name} className="t_img" />
        )}
      </td>
      <td className="text-start">{name}</td>
      <td className="text-start">{id}</td>
    </tr>
  );
};

const CycleDetails = () => {
  const navigate = useNavigate();

  const { cycleDetails } = useSelector(
    (state: any) => state.cycles
  );

  const handleClick = (code: any) => {
    // dispatch(fieldAgentsActions.fieldAgentDetails(code));
    navigate('details')
  };


  const [Cycle, setCycleDetail] = useState<any>();
  const tableHeadContents = ["No.", "PIC", "NAME", "ID"];

  return (
    <div className="cycle_details">
      <div className="close_container ">
        <div className="close_btn shadow-lg" onClick={() => navigate(-1)}>
          <MdArrowBack />
        </div>
      </div>

      <div className="mt-5 px-10">
        <div className="w-full max-w-[54.125rem] mx-auto flex justify-between items-center">
          <h1 className="font-semibold text-3xl leading-10 text-[#2A2A2A] ">
            Cycle Details
          </h1>
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-3 ring-1 ring-[#C4C4C4] rounded-[10px] px-4 py-2 cursor-pointer">
              <FiTrash2 className="w-5 h-5 text-red-500" aria-hidden="true" />
              <p className="text-xl leading-7 text-[#37847E] ">Remove</p>
            </div>
            <div className="flex items-center gap-3 ring-1 ring-[#C4C4C4] rounded-[10px] px-4 py-2 cursor-pointer">
              <FiEdit2 className="w-5 h-5 text-[#37847E]" aria-hidden="true" />
              <p className="text-xl leading-7 text-[#37847E] ">Edit</p>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[54.125rem] mx-auto flex items-center gap-14 justify-between mt-10">
          <div className="flex-1">
            <div className="border-b border-[#C4C4C4] pb-3 mb-3">
              <h4 className="font-semibold text-xl leading-7 text-[#2A2A2A]">
                Farmer Name
              </h4>
              <span className="font-normal text-xl leading-7 text-[#2A2A2A]">
                {" "}
                {cycleDetails?.name}{" "}
              </span>
            </div>
            <div className="border-b border-[#C4C4C4] pb-3 mb-3">
              <h4 className="font-semibold text-xl leading-7 text-[#2A2A2A]">
                Crop Type
              </h4>
              <span className="font-normal text-xl leading-7 text-[#2A2A2A]">
                {" "}
                {cycleDetails?.cropType}{" Maize"}
              </span>
            </div>
            <div className="border-b border-[#C4C4C4] pb-3 mb-3">
              <h4 className="font-semibold text-xl leading-7 text-[#2A2A2A]">
                Crop Variety
              </h4>
              <span className="font-normal text-xl leading-7 text-[#2A2A2A]">
                {" "}
                {cycleDetails?.variety}{" "}
              </span>
            </div>
            <div className="border-b border-[#C4C4C4] pb-3 mb-3">
              <h4 className="font-semibold text-xl leading-7 text-[#2A2A2A]">
                Start Date
              </h4>
              <span className="font-normal text-xl leading-7 text-[#2A2A2A]">
                {" "}
                {cycleDetails?.start_date}{" "}
              </span>
            </div>
            <div className="border-b border-[#C4C4C4] pb-3 mb-3">
              <h4 className="font-semibold text-xl leading-7 text-[#2A2A2A]">
                End Date
              </h4>
              <span className="font-normal text-xl leading-7 text-[#2A2A2A]">
                {" "}
                {cycleDetails?.end_date}{" "}
              </span>
            </div>
          </div>

          <div className="flex-1 bg-[#E7F6F4] flex flex-col justify-center items-center py-8 rounded-lg">
            <h3 className="font-medium text-xl leading-7 text-[#2A2A2A] mb-8">Cycle Progress</h3>
            <div>
              <CycleTimer start_date={cycleDetails.start_date} end_date={cycleDetails.end_date} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-14" data-aos-delay="200">
        <table>
          <thead className="table_head rounded-none px-4">
            <tr className="table-row">
              {tableHeadContents.map((tableHeadContent, i) => (
                <th
                  key={tableHeadContent}
                  className="table_head_content text-left">
                  {tableHeadContent}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {cycleDetails.farmers?.length === 0 ? (
              <tr className=" table-row cursor-default mt-6 bg-inherit text-gray-400 hover:text-gray-400 hover:bg-inherit">
                <td colSpan={4}>
                  <div>
                    <h4 className="text-center font-[500] text-lg">
                      No farmer found!
                    </h4>
                  </div>
                </td>
              </tr>
            ) : (
              cycleDetails.farmers?.map((fieldAgent: any, i: number) => (
                <TableTile key={i}  index={i} {...fieldAgent} handleClick={handleClick} />
              ))
            )}
          </tbody>
        </table>

        <div
          className="back_to_top"
          onClick={() => {
            window.scrollTo(0, 0);
          }}>
          <h4 className="cursor-pointer">Back to top</h4>
        </div>
      </div>
    </div>
  );
};

export default CycleDetails;
