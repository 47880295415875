import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";
import { STORE_ACTION_TYPES } from "../types";
import { addCycleActions } from "../slice/cycles/addCycleSlice";
import { CreateCycle } from "../apis/cycle.service";



interface getCyclesParams {
  adminId: string;
  page?: string;
}

export const fetchCycles = createAsyncThunk(
    "cycles/fetchCycles",
    async (cycleData: any ,page?: any) => {
      try {
  
        const response = await getAxios(BACKEND_BASE_URL).post(
          `/company/cycles`,
          cycleData
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );
  
  
export const createCycle = async (dispatch: any, cycleData: any) => {
  dispatch(addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.SEND_INFO]({}));
  try {
    await CreateCycle(cycleData);

    dispatch(addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.RESPONSE](true));
  } catch {
    dispatch(
      addCycleActions[STORE_ACTION_TYPES.ADD_CYCLE.FAILED](
        "Failed to add cycle"
      )
    );
  }
};
