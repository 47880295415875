import { useEffect, useState } from "react";
import { MdArrowBack, MdModeEdit } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { HiOutlineTrash } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { FieldAgentProfile } from "../../db/interfaces";
import "../../styles/field_agent_dashboard.css";
import { axiosInstance } from "../../redux/apis/axios";
import { toast, ToastContainer } from "react-toastify";
import { ADMIN_TOKEN,  } from "../../state_manager/constants";
import { useStateValue } from "../../state_manager/contextApi";
import { Avatar } from "@material-ui/core";
import ListFarmers from "./farmers/list_farmers";
import {
  convertFieldAgentData,
  convertFieldAgentQuery,
} from "../../functions/fieldAgents";
import { useSelector } from "react-redux";
import calculateAge from "../../functions/calculateAge";
import { fieldAgentsActions } from "../../redux/slice/fieldAgents/fieldAgentsSlice";
import { STORE_ACTION_TYPES } from "../../redux/types";
import { getCachedToken } from "../../service/common";

const FieldAgentDashboard = () => {
  const [isLoading, setLoadingState] = useState(false)
  const { user } = useSelector((state: any) => state.admin);
  const navigation = useNavigate();

  const {
    state: { fieldAgentsQuery },
    dispatch,
  } = useStateValue();

  const { fieldAgentDetails } = useSelector((state: any) => state.fieldAgents);
  const [approvalIndex, setApprovalIndex] = useState(fieldAgentDetails?.isApproved)



  const toggleApproval = async () => {
    setLoadingState(true)
    const token = getCachedToken();
    const index = approvalIndex?.toString() === "0" ? 1 : 0;


    await axiosInstance
      .post(
        `/admin/api/company/agent/approve`,
        {
          agent_id: fieldAgentDetails?.id,
          company_id: user?.company_id,
          is_approved: index,
        },
        { headers: { Authorization: `Bearer ${token?.token}` } }
      )
      .then((res) => {
        console.log("approve: ", res);
        let data = res.data;
        let { msg, code } = data;

        if (code !== 200) {
          toast(msg, { toastId: "fieldAgentDetails?.id" });
        }else {
          toast(msg, { toastId: "fieldAgentDashToast" });
          if(approvalIndex?.toString() === "0") {
            setApprovalIndex(1)
          }else {
            setApprovalIndex(0)
          }
        }
      })
      .catch((err) => toast(err));
      setLoadingState(false)
  };

  return (
    <div className="farmer_dashboard p-5">
      <ToastContainer
        hideProgressBar
        position="top-center"
        autoClose={1800}
        containerId={"fieldAgentDashToast"}
      />
      <div className="close_container">
        <div className="close_btn" onClick={() => navigation(-1)}>
          <MdArrowBack />
        </div>
      </div>
      <div className="actions_cont flex flex-row" data-aos="fade-up">
        <div className="actions_cont_inner_client w-screen gap-5">
          <div className="actions_btn_cont w-[100%] justify-between items-center ">
            <div>
              {" "}
              <h1 className="text-[24px] font-bold color-[#2A2A2A]">
                Agent Details
              </h1>
            </div>
            <div className="flex gap-5 mr-2">
              <button
                onClick={() => toggleApproval()}
                disabled={isLoading}
                className={`flex gap-1 items-center border px-3 py-1 rounded-[5px] border-[#C4C4C4] text-[16px] text-[#37847E]  hover:bg-[#37847E] hover:text-[#fff] ${isLoading && 'hover:bg-gray-400 bg-gray-400 hover:text-[#37847E]'}`}>
                {approvalIndex?.toString() === "1" ? (
                  <p className="flex items-center gap-1">
                    {" "}
                    <IoMdCheckmarkCircleOutline color="red" />
                    <span className="block">Disapprove</span>
                  </p>
                ) : (
                  <p className="flex items-center gap-1">
                    {" "}
                    <IoMdCheckmarkCircleOutline />
                    <span className="block">Approve</span>
                  </p>
                )}
              </button>
            </div>
          </div>
          <div className="actions_btn_cont w-[100%] gap-10">
            <div className="flex justify-center items-center w-2/5 h-[504px] rounded-[8px] bg-[#E7F6F4]">
              <div className=" flex flex-col items-center gap-10 w-fit">
                <div className="w-[204px] h-[204px] rounded-[102px]">
                  {fieldAgentDetails?.image ? (
                    <img
                      src={fieldAgentDetails?.image}
                      alt={fieldAgentDetails?.name}
                    />
                  ) : (
                    <Avatar
                      alt="name"
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "var(--primeCol)",
                      }}
                    />
                  )}
                </div>
                <div className="w-full border-b pb-4 border-b-[#C4C4C4] text-center">
                  <h1 className="text-[18px] font-bold mb-1 flex items-center justify-center gap-1">
                    <span className="block">{fieldAgentDetails?.name}</span>
                    {approvalIndex?.toString() === "1" && (
                      <IoMdCheckmarkCircleOutline color="#37847E" />
                    )}
                  </h1>

                  <p>{fieldAgentDetails?.phoneNumber}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5 w-3/5">
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold ">Age</h1>
                <h2>{calculateAge(fieldAgentDetails?.age)}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold ">Gender</h1>
                <h2>{fieldAgentDetails?.gender}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold ">Email</h1>
                <h2>{fieldAgentDetails?.email}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">Country</h1>
                <h2>{fieldAgentDetails?.country}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">State</h1>
                <h2>{fieldAgentDetails?.state}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">No. of Farmers</h1>
                <h2>{fieldAgentDetails?.farmers_count}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ListFarmers />
    </div>
  );
};

export default FieldAgentDashboard;
