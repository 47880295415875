import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";

export const Login = async (email: string, password: string) =>
  await getAxios(BACKEND_BASE_URL).post("/login", {email,password});


export const GetAdminDetails = async (admin_id: string) =>
  await getAxios(BACKEND_BASE_URL).post("/detail", {admin_id});



