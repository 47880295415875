import React, { useEffect, useState } from "react";
import { FarmProfile, FarmType } from "../db/interfaces";
import { getFarmTypes } from "../functions/general";
import { MdOutlineClose } from "react-icons/md";

const initialFarmProfile: FarmProfile = {
  id: 0,
  landSize: 0,
  stateId: 0,
  town: "",
  district: "",
  farmTypeId: 0,
};

interface TextInputProps {
  label: string;
  value: string | number;
  type?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  name?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  onChange,
  placeholder,
  type,
  name,
}) => {
  return (
    <div className="form_input w-full sm:w-1/2">
      <h3>{label}</h3>
      <input
        className="w-full"
        type={type ? type : "text"}
        required
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

interface FarmFormProps {
  setValue?: any;
  resetData?: boolean;
  data?: [];
  states?: any;
}

const FarmForm: React.FC<FarmFormProps> = ({
  setValue,
  resetData,
  data,
  states,
}) => {
  const [farms, setFarms] = useState<FarmProfile[]>(
    data ? data : [initialFarmProfile]
  );
  const [farmTypes, setFarmTypes] = useState<FarmType[]>([]);

  useEffect(() => {
    let fetchedFarmTypes = getFarmTypes();
    fetchedFarmTypes.then((res) => setFarmTypes(res));
  }, []);

  useEffect(() => {
    resetData && setFarms([initialFarmProfile]);
  }, [resetData]);

  const handleChange = (
    index: number,
    field: keyof FarmProfile,
    value: string | number
  ) => {
    const updatedFarms = farms?.map((farm, i) => {
      if (i === index) {
        return { ...farm, [field]: value };
      }
      return farm;
    });
    setFarms(updatedFarms);

    // Check if farms array is not empty and the first farm object has the required attribute
    if (farms.length > 0 && farms[0][field]) {
      setValue((prevData: any) => ({
        ...prevData,
        farms: updatedFarms,
      }));
    }
  };

  const handleDeleteFarm = (index: number) => {
    const updatedFarms = farms.filter((farm, i) => i !== index);
    setFarms(updatedFarms);
    setValue((prevData: any) => ({
      ...prevData,
      farms: updatedFarms,
    }));
  };

  const addFarm = () => {
    setFarms([...farms, initialFarmProfile]);
  };

  return (
    <div className="mt-10">
      <h3 className="title text-[16px] font-semibold text-[#2A2A2A]">
        Farm Info Section
      </h3>

      {farms?.map((farm, index) => (
        <div className="mt-6" key={index}>
          {}
          <div className="flex justify-between">
            <h3 className="text-[#37847E] text-1xl font-bold">
              Farm {index + 1}
            </h3>
            {farms.length > 1 && (
              <p
                role="button"
                title="Delete Farm"
                className="cursor-pointer  text-2xl text-[#37847E]
                 hover:text-red-500 delay-100"
                onClick={() => handleDeleteFarm(index)}>
                <MdOutlineClose />
              </p>
            )}
          </div>
          <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
            <TextInput
              label="Farm District"
              name="district"
              value={farm.district}
              onChange={(value) => handleChange(index, "district", value)}
              placeholder="District"
            />
            <div className="form_input w-full sm:w-1/2">
              <h3>State</h3>
              <select
                className="w-full"
                name="stateId"
                required
                value={farm.stateId}
                onChange={(e) =>
                  handleChange(index, "stateId", e.target.value)
                }>
                {/* <option value="">Select Farm Type</option> */}
                {states?.map((state: any) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
            <TextInput
              label="Land Size(acre/s)"
              name="landSize"
              type="number"
              value={farm.landSize}
              onChange={(value) => handleChange(index, "landSize", value)}
              placeholder="Land Size"
            />
            <div className="form_input w-full sm:w-1/2">
              <h3>Farm Type</h3>
              <select
                className="w-full"
                name="farmTypeId"
                required
                value={farm.farmTypeId}
                onChange={(e) =>
                  handleChange(index, "farmTypeId", e.target.value)
                }>
                <option value="">Select Farm Type</option>
                {farmTypes?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
            <TextInput
              label="Town"
              name="town"
              value={farm.town}
              onChange={(value) => handleChange(index, "town", value)}
              placeholder="Town"
            />
          </div>
        </div>
      ))}
      <div className="mt-5">
        <p
          role="button"
          className="cursor-pointer hover:underline text-base text-[#37847E] w-fit"
          onClick={addFarm}>
          +Add More Farm
        </p>
      </div>
    </div>
  );
};

export default FarmForm;
