import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";

export const GetGroups = async (
  admin_id: number,
  company_id: number,
  paginate?: string
) =>
  await getAxios(BACKEND_BASE_URL).post(
    `/company/group${paginate ? "?" + paginate : ''}`,
    { company_id: company_id, admin_id: admin_id }
  );

  export const GetGroupDetails = async (group_id: any, company_id: any) =>
    await getAxios(BACKEND_BASE_URL).post(`/company/group/details`, {
      group_id, company_id
    });


export const AddGroup = async (
  group_data: any,
) =>
  await getAxios(BACKEND_BASE_URL).post(
    `/company/group/store`,
    group_data
  );
