import { HiOutlinePlusSm, HiOutlineRefresh } from "react-icons/hi";
import {
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { fetchClients } from "../redux/actions/clients";
import { useDispatch } from "react-redux";
import ExtractPageParamenter from "./ExtractPageParamenter";
import DropDownNav from "../pages/companies/drop_down_nav";

interface IPaginator {
  meta: any;
  title?: string;
  addUrl: string;
  adminId?: any;
  handleOptionChange?: any;
}

const Paginator = ({
  meta,
  addUrl,
  title,
  adminId,
  handleOptionChange,
}: IPaginator) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePagination = (url: string | null) => {
    if (url) {
      const page = ExtractPageParamenter(url);
      // dispatch(fetchClients({ admin_id: adminId, page})); 
    }
  };

  return (
    <div
      className="actions_cont"
      // data-aos="fade-up" data-aos-delay="150"
    >
      <div className="actions_cont_inner">
        <div className="actions_btn_cont items-center">
          <DropDownNav handleOptionChange={handleOptionChange} />
          <div
            className="action_btn"
            title="refresh table"
            onClick={() => handlePagination(meta?.first_page_url)}
          >
            <HiOutlineRefresh />
          </div>

          <div
            className="action_btn"
            title={`add ${title}`}
            onClick={() => navigate(addUrl)}
          >
            <HiOutlinePlusSm color="#37847E" />
          </div>
        </div>
        <div className="table_pagination_cont">
          <div className="table_page_numbers">
            <h4>
              {meta?.current_page} - {meta?.to} of {meta?.total}
            </h4>
          </div>

          <div className="table_page_control">
            <div
              className={`control_btn ${
                !meta?.prev_page_url ? "control_inactive" : ""
              }`}
              title="Previous Page"
              onClick={() => handlePagination(meta?.prev_page_url)}
            >
              <MdOutlineChevronLeft />
            </div>
            <div className="table_page_numbers">
              <h4>{meta?.current_page}</h4>
            </div>
            <div
              className={`control_btn ${
                !meta?.next_page_url ? "control_inactive" : ""
              }`}
              title="Next Page"
              onClick={() => handlePagination(meta?.next_page_url)}
            >
              <MdOutlineChevronRight />
            </div>

            <div
              className={`control_btn ${
                !meta?.first_page_url ? "control_inactive" : ""
              }`}
              title="First Page"
              onClick={() => handlePagination(meta?.first_page_url)}
            >
              <MdOutlineExpandLess />
            </div>
            <div
              className={`control_btn ${
                !meta?.last_page_url ? "control_inactive" : ""
              }`}
              title="Last Page"
              onClick={() => handlePagination(meta?.last_page_url)}
            >
              <MdOutlineExpandMore />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
