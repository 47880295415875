import {
  MdOutlinePersonAdd,
  MdOutlineSearch,
  MdPersonOutline,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "../../styles/field_agents.css";
import { useEffect, useState } from "react";
import { Loading2 } from "../../components/loading";
import { Avatar } from "@material-ui/core";
import SmAnalysis from "../../components/sm_analysis";
import { useDispatch, useSelector } from "react-redux";
import { requestFieldAgents } from "../../redux/actions/fieldAgents";
import Paginator from "../../components/paginator";
import { fieldAgentsActions } from "../../redux/slice/fieldAgents/fieldAgentsSlice";

interface FieldAgent {
  id: string;
  name: string;
  age: string;
  image: string;
  handleClick: Function;
}

const TableTile = ({ id, name, age, image, handleClick }: FieldAgent) => {
  const [isImageValid, setIsImageValid] = useState<boolean>(false);

  return (
    <tr
      className="table-row"
      onClick={() => handleClick(id)}>
      <td className="text-center">{id}</td>
      <td className="flex justify-center">
        {isImageValid ? (
          <img
            src={`https://backend.lbhfarm.com/storage/${image}`}
            alt={name}
            onError={(e) => {
              setIsImageValid(!isImageValid);
            }}
          />
        ) : (
          <Avatar alt={name} className="t_img " />
        )}
      </td>
      <td className="text-center">{name}</td>
      <td className="text-center">{age}</td>
    </tr>
  );
};

const FieldAgents = () => {
  const tableHeadContents = ["No.", "PIC", "NAME", "ID"];
  const [fieldAgentsDataLoading, setFieldAgentsDataLoading] =
    useState<boolean>(false);
  const [searchData, setSearchData] = useState({ keyword: "", type: "name" });

  const { user } = useSelector((state: any) => state.admin);
  const { fieldAgents, isLoading, meta, failedMsg } = useSelector(
    (state: any) => state.fieldAgents
  );
  const navigate = useNavigate()
  const dispatch = useDispatch();

  if (failedMsg) console.log(failedMsg);

  useEffect(() => {
    requestFieldAgents(dispatch, user?.company_id);
  }, []);

  const handleClick = (code: any) => {
    dispatch(fieldAgentsActions.fieldAgentDetails(code));
    navigate('details')
  };

  const onSearchSubmit = async () => {
    setFieldAgentsDataLoading(true);
    if (!searchData.keyword) {
      window.location.reload();
    }
  };

  if (isLoading) return <Loading2 />;

  return (
    <div className="field_agents">
      <div className="field_agents_inner">
        <SmAnalysis
          title="Field Agents"
          Icon1={MdPersonOutline}
          Icon2={MdOutlinePersonAdd}
          total={meta?.total ?? 0}
          totalToday={meta?.today ?? 0}
        />

        <div className="search_filter_cont" data-aos="fade-up">
          <div className="search_cont">
            <MdOutlineSearch className="search_icon" />
            <input
              type="text"
              placeholder="search field agent"
              onChange={(e) =>
                setSearchData({ ...searchData, keyword: e.target.value })
              }
              onKeyDown={(e) => e.key === "Enter" && onSearchSubmit()}
            />
          </div>
          <div className="filter_cont">
            <select
              name="filter"
              className="dropdown"
              onChange={(e) =>
                setSearchData({ ...searchData, type: e.target.value })
              }>
              <option value="name">Name</option>
              <option value="region">Region</option>
              <option value="town">Town</option>
            </select>
          </div>
        </div>

        <Paginator
          meta={meta}
          addUrl="/fieldagentadd"
          title="Add Field Agent"
        />

        {/* FIELD AGENTS TABLE */}
        <div className="list_farmers_cont" data-aos-delay="200">
          <table>
            <thead className="table_head">
              <tr className="table-row">
                {tableHeadContents.map((tableHeadContent, i) => (
                  <th
                    key={tableHeadContent}
                    className="table_head_content text-center">
                    {tableHeadContent}
                  </th>
                ))}
              </tr>
            </thead>
            {fieldAgentsDataLoading ? (
              <tbody>
                <tr className="table-row">
                  <td colSpan={4} style={{ backgroundColor: "#ffff" }}>
                    <div className="flex justify-center mt-16 ">
                      <div className="load__indicator__box">
                        <div className="load__indicator"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {fieldAgents?.length === 0 ? (
                  <tr className=" table-row cursor-default mt-6 bg-inherit text-gray-400 hover:text-gray-400 hover:bg-inherit">
                    <td colSpan={4}>
                      <div>
                        <h4 className="text-center font-[500] text-lg">
                          No farmer found!
                        </h4>
                      </div>
                    </td>
                  </tr>
                ) : (
                  fieldAgents?.map((fieldAgent: any, i: number) => (
                    <TableTile key={i} {...fieldAgent} handleClick={handleClick} />
                  ))
                )}
              </tbody>
            )}

            {/* <tbody>
              {fieldAgents?.map((fieldAgent: any, i: number) => (
                <TableTile key={i} {...fieldAgent} />
              ))}
            </tbody> */}
          </table>

          <div
            className="back_to_top"
            onClick={() => {
              window.scrollTo(0, 0);
            }}>
            <h4 className="cursor-pointer">Back to top</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldAgents;
