import { MdOutlineClose } from "react-icons/md";
import ServiceReqForm from "./service_req_form";

interface ModalInfo {
  closeModal: Function;
}

const ServiceRequestModal = ({ closeModal }: ModalInfo) => {
  return (
    <div className="w-[600px] bg-white">
      <div id="header" className="bg-[#37847E] p-4">
        <div className="flex justify-between items-center">
          <div className="text-white font-semibold text-xl">
            Request Service
          </div>
          <button
            className="block text-white font-semibold text-3xl cursor-pointer"
            onClick={() => closeModal(false)}>
            <MdOutlineClose />
          </button>
        </div>
        <p className="text-[#C4C4C4] text-sm ">
          We will connect you to a dealer
        </p>
      </div>
      <div id="form" className="py-5 px-8">
        <ServiceReqForm />
      </div>
    </div>
  );
};

export default ServiceRequestModal;
