import logo2 from '../assets/images/logo2.png'
import {MdOutlineHome,MdOutlineSettings, MdOutlineLogout, MdOutlineMenu, MdOutlineClose} from 'react-icons/md'
import '../styles/navbar.css'
import { Link, useMatch, useNavigate, useResolvedPath  } from 'react-router-dom'
import { toggleDarkMode, toggleSideNavbar } from '../functions/navbar'
import { useEffect, useState} from 'react'
import { useStateValue } from '../state_manager/contextApi'
import { adminLogout } from '../redux/actions/auth'
import { useDispatch } from 'react-redux'






let NavigationTile = ({ Icon , navText,url }:{Icon? : any , navText? : string , url : string })=>{
  let resolved = useResolvedPath(url);
  let match = useMatch({ path: resolved.pathname, end : true});
  return(
    <Link to={url} className={`navigation_tile ${match && 'navigation_tile_active'}`}>
      {
        <Icon />
      }
      <h4>{navText}</h4>
    </Link>
  )
}




const Navbar = () => {
  const [isNavbarActive, setIsNavbarActive] = useState<boolean>(false)

  const handleNavMenuClick = () => {
    toggleSideNavbar()
    setIsNavbarActive((prev)=>!prev)
  }



  const navigate = useNavigate();
  const {state : {theme},} = useStateValue();
  const dispatch = useDispatch();
  const isDark = theme === "dark";

  const logout = (e:any)=>{
    e.preventDefault();
    if(window.confirm("You would be logged out of the system.")){
      adminLogout(dispatch)
    }


  }

  useEffect(()=>{

  },[])



  return (
    <div className="navbar">
      <div className='navbar_inner'>
        <div className='menu_n_logo'>
          {/* MENU BUTTON */}
          <div className='menu_btn' onClick={handleNavMenuClick}>
            {
              isNavbarActive?
               (<MdOutlineClose className='menu_icon'/>)
               :
               (<MdOutlineMenu className='menu_icon'/>)
            }
          </div>

          {/* LOGO */}
          <div className="logo_cont" onClick={()=> navigate("")}>
            <img src={logo2} alt="lbh_logo2" />
          </div>
        </div>

        {/* NAVIGATIONS */}
        {/* <div className='navigations'>
          <NavigationTile   Icon={MdOutlineHome} url = "" navText = {'Home'}/>
          <NavigationTile   Icon={MdOutlineGroup} url = "dashboards" navText = {'Dashboards'}/>
          <NavigationTile   Icon={MdOutlineSettings} url = "settings" navText = {'Settings'}/>
        </div> */}

        {/* ACTIONS */}
        <div className='actions'>
          <NavigationTile Icon={MdOutlineHome} url = "" />
          <NavigationTile Icon={MdOutlineSettings} url = "settings" />

          <div className={`dark_mode_toggle_cont `} onClick={()=>toggleDarkMode(theme,dispatch)}>
            <span>🌜</span>
            <span>🌞</span>
            <div className={`dark_mode_indicator ${ isDark ? 'dark_mode_indicator_active' : ''}`}></div>
          </div>

          <div className='logout_cont' onClick={logout}>
            <MdOutlineLogout/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
