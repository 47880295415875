import React, { useState } from "react";

// import { MdModeEdit } from "react-icons/md";
import { MdArrowBack, MdModeEdit } from "react-icons/md";
import { FaEllipsisV } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import Paginator from "../../../components/paginator";
import ListAdmins from "../admins/list_admins";
import ListAgents from "../Agents/list_agents";
import ListFarmers from "../farmers/list_farmers";
const ClientDetails = () => {
  const navigate = useNavigate();
  const { clientDetails } = useSelector((state: any) => state.clients);

  const [selectedOption, setSelectedOption] = useState<string>("admins");

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  return (
    <div className="farmer_dashboard p-5">
      <div className="close_container">
        <div className="close_btn" onClick={() => navigate(-1)}>
          <MdArrowBack />
        </div>
      </div>
      <div className="actions_cont flex flex-row" data-aos="fade-up">
        <div className="actions_cont_inner_client w-screen gap-5">
          <div className="actions_btn_cont w-[100%] justify-between items-center">
            <div>
              {" "}
              <h1 className="text-[24px] font-bold color-[#2A2A2A]">
                Client Details
              </h1>
            </div>
            <div className="flex gap-5">
              <button className="border p-1 rounded-[5px] border-[#C4C4C4] text-[16px] text-[#37847E] hover:bg-[#37847E] hover:text-[#fff]">
                Deactivate
              </button>
              <button className="flex gap-1 items-center border p-1 rounded-[5px] border-[#C4C4C4] text-[16px] text-[#37847E]  hover:bg-[#37847E] hover:text-[#fff]">
                <MdModeEdit />
                Edit
              </button>
              <button className="">
                <FaEllipsisV />
              </button>
            </div>
          </div>
          <div className="actions_btn_cont w-[100%] gap-10">
            <div className="flex flex-col items-center gap-10 pt-[20px] w-2/5 h-[504px] rounded-[8px] bg-[#E7F6F4]">
              <div className="w-[204px] h-[204px] rounded-[102px]">
                <Avatar
                  alt="name"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "var(--primeCol)",
                  }}
                />
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4] text-center">
                <h1 className="text-[18px] font-bold">{clientDetails.name}</h1>
              </div>
            </div>
            <div className="flex flex-col gap-5 w-3/5">
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold ">Email</h1>
                <h2>{clientDetails.email}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">Country</h1>
                <h2>{clientDetails.country}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">State</h1>
                <h2>{clientDetails.state}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">City</h1>
                <h2>{clientDetails.city}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">No. of Administrators</h1>
                <h2>{clientDetails.admins_count}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">No. of Agents</h1>
                <h2>{clientDetails.agents_count}</h2>
              </div>
              <div className="w-1/2 border-b border-b-[#C4C4C4]">
                <h1 className="text-[18px] font-bold">No. of Farmers</h1>
                <h2>{clientDetails.farmers_count}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients_inner">
        <Paginator
          title="Add Client"
          addUrl="/add-admin"
          meta="name"
          adminId="nMW"
          handleOptionChange={handleOptionChange}
        />

        {(() => {
          switch (selectedOption) {
            case "admins":
              return <ListAdmins />;
            case "agents":
              return <ListAgents />;
            case "farmers":
              return <ListFarmers />;
            default:
              return <ListFarmers />;
          }
        })()}
      </div>
    </div>
  );
};

export default ClientDetails;
