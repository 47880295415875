import { createSlice } from "@reduxjs/toolkit";
import { createAdmin } from "../../actions/admin";


interface AdminState {
  isLoading: boolean;
  error: string | null;
  message: any;
  adminData: {
    name: string;
    email: string;
    phone: string;
    country_id: number;
    state_id: number;
    is_approved?: number;
    company_id?: number;
    image?: string;
    password: string;
    password_confirmation: string;
  };
}

const initialState: AdminState = {
  isLoading: false,
  error: null,
  message: {},
  adminData: {
    name: "",
    email: "",
    phone: "",
    country_id: 0,
    state_id: 0,
    is_approved: 1,
    company_id: 0,
    image: "",
    password: "",
    password_confirmation: "",
  },
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateAdminDetails: (state, {payload}) => {
      state.adminData = {
        ...state.adminData,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdmin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createAdmin.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.message = payload;
      })
      .addCase(createAdmin.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        state.error = payload.msg;
      });
  },
});

export const addAdminActions = adminSlice.actions
export default adminSlice.reducer;
