import React from 'react'
import { useNavigate } from 'react-router-dom'

const CycleWelcome = () => {
    const navigate = useNavigate();
  return (
    <div style={styles.body}>
        <div style={styles.bodyInner}>
            <div>
                <h1>Welcome Back</h1>
                <h4 style={{fontWeight : "normal"}}>Please setup dashboard your dashboard to continue</h4>
            </div>

            <div data-aos = "fade-up" style={{display : 'grid', placeItems : "center", width : "100%", minHeight : "90vh"}}> 
                <div style={{width : "100%", maxWidth : "400px", display : "flex", flexDirection : "column", alignItems : "center"}}>
                    <div style={{width : "100%", height: "400px", background : "green"}}>

                    </div>

                    <button onClick={()=> navigate("/cycleadd")} style={styles.button}>Continue to setup</button>
                </div>
            </div>
        </div>

    </div>
  )
}

export default CycleWelcome



const styles : any = {
    body : {
        width : "100%",
        padding : "20px",
        color : "var(--text)"
    },

    bodyInner : {
        width : "100%"
    },

    button : {
        width: "100%",
        maxWidth : "300px",
        border: "none",
        outline: "none",
        backgroundColor: "var(--primeCol)",
        color: "var(--white)",
        padding: "14px 16px",
        borderRadius: "15px",
        height: "50px",
        cursor: "pointer",
        marginTop: "15px",
    }
}