import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../../service/common";
import { BACKEND_HELPERS_BASE_URL } from "../../state_manager/constants";

export interface Country {
  id: number;
  name: string;
  short_name: string;
  flag_img: string;
  country_code: string;
  created_at: string;
  updated_at: string;
}
export interface States {
  id: number;
  country_id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export const getCountries = createAsyncThunk<Country[], void>(
  'countries/getCountries',
  async (_, { dispatch }) => { // Use the second argument to access dispatch
    try {
      const response = await getAxios(BACKEND_HELPERS_BASE_URL, dispatch).get(
        '/countries/list'
      );
      return response.data as Country[];
    } catch (error) {
      throw error;
    }
  }
);

export const getStates = createAsyncThunk<States[], string>(
  "states/getState",
  async (country_id, { dispatch }) => {
    try {
      const response = await getAxios(BACKEND_HELPERS_BASE_URL, dispatch).get(
        `/regions/list/${country_id}`
      );
      return response.data as States[];
    } catch (error) {
      throw error;
    }
  }
);
