import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";


export const GetFieldAgents = async (company_id: string) =>
  await getAxios(BACKEND_BASE_URL).post("/company/list/agents", {company_id});

export const AddFieldAgent = async (fieldAgentData: any) =>
  await getAxios(BACKEND_BASE_URL).post("/company/agent/register", { form_data:fieldAgentData});


export const SearchFieldAgent = async (query: {keyword: string,type: string}) =>
  await getAxios(BACKEND_BASE_URL).post("/search/agent", query);



