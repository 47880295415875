import { combineReducers, configureStore } from "@reduxjs/toolkit";
import adminLoginSlice from "./slice/auth/adminLoginSlice";
import clientsSlice from "./slice/client/clientsSlice";
import addClientSlice from "./slice/client/addClientSlice";
import farmersSlice from "./slice/farmers/farmersSlice";
import fieldAgentsSlice from "./slice/fieldAgents/fieldAgentsSlice";
import adminSlice from "./slice/client/adminSlice";
import countriesStateSlice from "./slice/country_state/countriesStateSlice";
import groupsSlice from "./slice/groups/groupsSlice";
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import cyclesSlice from "./slice/cycles/cyclesSlice";
import addCycleSlice from "./slice/cycles/addCycleSlice";

// persist some part of the clients data in session storage
const persistedClientsSlice = persistReducer(
  {
    key: 'clients', // Specify a key for the clients state
    storage: storageSession,
    whitelist: ['clientDetail', 'clientDetails'], // Only persist these properties
  },
  clientsSlice
);

// persist some part of the farmer data in session storage
const persistedFarmerSlice = persistReducer(
  {
    key: 'farmers', // Specify a key for the clients state
    storage: storageSession,
    whitelist: ['farmerDetails', 'farmerDetail'], // Only persist these properties
  },
  farmersSlice
);

// persist some part of the fieldAgent data in session storage
const persistedFieldAgentsSlice = persistReducer(
  {
    key: 'fieldAgent', // Specify a key for the clients state
    storage: storageSession,
    whitelist: ['fieldAgentDetails', 'fieldAgentDetail'], // Only persist these properties
  },
  fieldAgentsSlice
);

// persist some part of the group data in session storage
const persistedGroupsSlice = persistReducer(
  {
    key: 'groups', // Specify a key for the clients state
    storage: storageSession,
    whitelist: ['viewGroup', 'groups'], // Only persist these properties
  },
  groupsSlice
);
const persistedCyclesSlice = persistReducer(
  {
    key: 'cycles', // Specify a key for the clients state
    storage: storageSession,
    whitelist: ['meta', 'cycles', 'cycleDetail', 'cycleDetails'], // Only persist these properties
  },
  cyclesSlice
);

const rootReducer = combineReducers({
  countriesState: countriesStateSlice,
  admin: adminLoginSlice,
  farmers: persistedFarmerSlice,
  fieldAgents: persistedFieldAgentsSlice,
  clients: persistedClientsSlice,
  addClient: addClientSlice,
  addAdmin: adminSlice,
  groups: persistedGroupsSlice,
  cycles: persistedCyclesSlice,
  addCycle: addCycleSlice
})


export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});


export const persistor = persistStore(store)
