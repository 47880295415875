import { MdOutlineSearch } from "react-icons/md";
import { useState } from "react";
import tractor_img from "./images/Rectangle 121 (1).png";
import ServiceAddModal from "../../components/service/service_add_modal";
import ServiceRequestModal from "../../components/service/service_request_modal";
import { useNavigate } from "react-router-dom";

interface Card {
  name: string;
  img: string;
  description: string;
  activateModal: Function;
}

const Card = ({ name, img, description, activateModal }: Card) => {
  return (
    <div className="card_container mb-6 overflow-hidden h-[340px]">
      <div className="img_cont rounded-2xl">
        <img src={img} alt="name" className="w-full" />
      </div>
      <h2 className="text-lg text-[#2A2A2A] font-semibold my-2">{name}</h2>
      <p className="text-[#C4C4C4] text-sm">{description}</p>
      <div className="acc_btn rounded-lg text-sm bg-opacity-70  bg-[#37847E] text-white cursor-pointer  border-[#37847E] hover:font-medium hover:bg-[#2a6b66] delay-100 w-fit mt-2">
        <button
          className="w-fit  h-full py-[10px] px-8"
          onClick={() => activateModal(true)}>
          Request Service
        </button>
      </div>
    </div>
  );
};

const Service = () => {
  const navigation = useNavigate();
  const [searchData, setSearchData] = useState({ keyword: "", type: "name" });
  const [isAddModalVisible, setAddModal] = useState<boolean>(false);
  const [isReqModalVisible, setReqModal] = useState<boolean>(false);

  const onSearchSubmit = async () => {};
  return (
    <div className="Services w-full  m-auto p-3 md:p-5  max-w-[1200px] min-h-screen">
      <section
        id="top"
        data-aos="fade-up"
        data-aos-delay="100"
        className="flex justify-between items-center overflow-hidden my-4 mr-7">
        <div className="title text-[28px] font-medium text-[#2A2A2A]">
          Services
        </div>
        <div className="search_bar flex items-center justify-center bg-white w-64 rounded-[10px] py-[8px]">
          <MdOutlineSearch className="search_icon ml-2" color="#C4C4C4" />
          <input
            className="text-[#2A2A2A] outline-none bg-inherit h-full"
            type="text"
            placeholder="Search Location"
            onChange={(e) =>
              setSearchData({ ...searchData, keyword: e.target.value })
            }
            onKeyDown={(e) => e.key === "Enter" && onSearchSubmit()}
          />
        </div>
        <div className="action_buttons  flex gap-3">
          <div className="req_btn rounded-lg py-[10px] px-4 text-sm bg-white cursor-pointer border-[2px]  hover:text-[#2a6b66] hover:border-[#2a6b66] hover:font-medium hover:bg-[#efefef] delay-100">
            <button
              className="w-full h-full "
              onClick={() => navigation("requested")}>
              Requested Service
            </button>
          </div>
          <div className="acc_btn rounded-lg text-sm bg-[#37847E] text-white cursor-pointer border-[2px] border-[#37847E] hover:font-medium hover:bg-[#2a6b66] delay-100">
            <button
              className="py-[10px] px-8 "
              onClick={() => navigation("accepted")}>
              Accept
            </button>
          </div>
        </div>
      </section>
      <section id="main_content" className="bg-white rounded-xl px-5 py-6">
        <section
          data-aos="fade-up"
          data-aos-delay="150"
          id="service_section"
          className="mb-6">
          <div className="flex justify-between items-center mb-5">
            <p className="text-xl text-[#2A2A2A] font-semibold ml-5 ">
              Service
            </p>
            <p className="cursor-pointer hover:font-medium delay-100">
              See All
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {data.map(
              (item, i) =>
                i <= 7 && (
                  <Card
                    key={i}
                    name={item.name}
                    img={item.img}
                    description={item.description}
                    activateModal={setReqModal}
                  />
                )
            )}
          </div>
        </section>
        <section id="my_service" className="mt-28 mb-20">
          <div className="flex justify-between items-center mb-5 ">
            <p className="text-xl text-[#2A2A2A] font-semibold ml-5">
              My Services
            </p>
            <div className="acc_btn rounded-lg text-sm bg-[#37847E] text-white cursor-pointer border-[2px] border-[#37847E] hover:font-medium hover:bg-[#2a6b66] delay-100">
              <button
                className="w-full h-full py-[10px] px-8"
                onClick={() => setAddModal(true)}>
                Add Service
              </button>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="150"
            className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {data.map(
              (item, i) =>
                i <= 3 && (
                  <Card
                    key={i}
                    name={item.name}
                    img={item.img}
                    description={item.description}
                    activateModal={setReqModal}
                  />
                )
            )}
          </div>
        </section>
      </section>

      {(isAddModalVisible || isReqModalVisible) && (
        <div
          className="fixed top-0 w-full max-w-[1200px] h-full flex justify-center items-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
          {isAddModalVisible && <ServiceAddModal closeModal={setAddModal} />}
          {isReqModalVisible && (
            <ServiceRequestModal closeModal={setReqModal} />
          )}
        </div>
      )}
    </div>
  );
};

export default Service;

const data = [
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
  {
    name: "Tractor Service",
    img: tractor_img,
    description: "Lorem ipsumdolor sit ametconsectetur.consectetur",
  },
];
