import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/farmers_table.css";
import { Avatar } from "@material-ui/core";

function FarmersTable({ listFarmers, farmerListLoading, handleClick }: any) {
  const tableHeadContents = ["PIC", "NAME","FARM TYPE", "ID"];


  return (
    <table>
      <thead className="table_head">
        <tr className="table-row">
          {tableHeadContents.map((tableHeadContent, i) => (
            <th key={i} className="table_head_content text-center">
              {tableHeadContent}
            </th>
          ))}
        </tr>
      </thead>

      {farmerListLoading ? (
        <tbody>
          <tr className="table-row">
            <td colSpan={4} style={{ backgroundColor: "#ffff" }}>
              <div className="flex justify-center mt-16 ">
                <div className="load__indicator__box">
                  <div className="load__indicator"></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {listFarmers?.length === 0 ? (
            <tr className="table-row cursor-default mt-6 bg-inherit text-gray-400 hover:text-gray-400 hover:bg-inherit">
              <td colSpan={4}>
                <div>
                  <h4 className="text-center font-[500] text-lg">
                    No farmer found!
                  </h4>
                </div>
              </td>
            </tr>
          ) : (
            listFarmers?.map((farmer: any) => (
              <TableTile key={farmer.id} {...farmer} handleClick={handleClick} />
            ))
          )}
        </tbody>
      )}
    </table>
  );
}

export default FarmersTable;

const TableTile = ({ id, name, image, farmer_uid, handleClick, farms }: any) => {
  const navigate = useNavigate();
  const [isImageValid, setIsImageValid] = useState<boolean>(true);

  return (
    <tr className="table_tile table-row" onClick={() => handleClick(id)}>
      <td className="flex justify-center">
        {isImageValid ? (
          <img
            src={`https://backend.lbhfarm.com/storage/${image}`}
            alt={name}
            onError={(e) => {
              setIsImageValid(!isImageValid);
            }}
          />
        ) : (
          <Avatar alt={name} className="t_img " />
        )}
      </td>

      <td className="text-center">{name}</td>
      <td className="text-center">{farms[0]?.get_farm_type.name}</td>
      <td className="text-center">{farmer_uid}</td>
    </tr>
  );
};
