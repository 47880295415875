import axios from "axios";
import { axiosInstance } from "../redux/apis/axios";
import { getAxios } from "../service/common";

export const setTheme = (theme: string) => {
  if (localStorage) localStorage.setItem("theme", theme);
};

export const getTheme = () => {
  if (localStorage) return localStorage.getItem("theme");
};

export const getCountries = async () => {
  let countries = await axiosInstance.post(`/api/helpers/countries/list`);
  return countries;
};

export const getRegions = async () => {
  let regions = await axiosInstance.post(`/api/helpers/regions/list`);
  return regions;
};

export const getFarmTypes = async () => {
  try {
   
    const response  = await getAxios("https://backend.lbhfarm.com/admin/api/").get(
        "/farmtypes"
      )

    const { code, msg } = response.data;

    if (code === 200) {
      return msg;
    } else {
      console.log(`Unexpected response code: ${code}`);
      return null;
    }
  } catch (error) {
    console.log("Error fetching farm types:", error);
    return null;
  }
};

export const getListCompanies = async () => {
  let companies = await axiosInstance
    .post(`/api/helpers/companies/list`)
    .then((res) => console.log("List companies res:", res))
    .catch((err) => console.log(err));

  return companies;
};
