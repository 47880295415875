export const APP_KEY = 'LbH_key'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'


export const SET_DARK_THEME = "SET_DARK_THEME"
export const SET_LIGHT_THEME = "SET_LIGHT_THEME"



export const ADD_FARMERS = "ADD_FARMERS"
export const UPDATE_FARMERS = "UPDATE_FAMERS"
export const DELETE_FARMERS = "DELETE_FAMERS"
export const DELETE_FARMER = "DELETE_FARMER"



export const ADD_FIELD_AGENTS= "ADD_FIELD_AGENTS"
export const UPDATE_FIELD_AGENT= "UPDATE_FIELD_AGENTS"
export const DELETE_FIELD_AGENT= "DELETE_FIELD_AGENTS"



export const ADMIN_TOKEN = "token"
export const ADMIN_DATA = "ADMIN_DATA"




export const BACKEND_BASE_URL = "https://backend.lbhfarm.com/admin/api"
export const BACKEND_HELPERS_BASE_URL = "https://backend.lbhfarm.com/api/helpers/"
