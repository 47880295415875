import { FaEllipsisV } from "react-icons/fa";

const DropDownNav = ({ handleOptionChange }: any) => {
  return (
    <div className="dropdown dropdown-hover ">
      <div tabIndex={0} role="button" className=" m-1">
        <FaEllipsisV />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
      >
        <li onClick={() => handleOptionChange("admins")}>
          <span>Admins</span>
        </li>
        <li onClick={() => handleOptionChange("agents")}>
          <span>Agents</span>
        </li>
        <li onClick={() => handleOptionChange("farmers")}>
          <span>Farmers</span>
        </li>
      </ul>
    </div>
  );
};

export default DropDownNav;
