import  { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import '../styles/map.css'
import { MapContainer, Polygon,useMapEvent} from "react-leaflet";
import { useMemo, useState} from "react";
import { LayersControlComp } from "./layer_controls";
import ghMapData from '../db/ghana_regions.json'



const MapComp :  React.FC<{center : LatLngExpression , zoom : number}> = ({center , zoom})=>{

  function ChangeView({_center , _zoom} :{_center : any, _zoom : number }) {
    const map = useMapEvent('click', () => {
      map.setView(_center,_zoom)
    })
    return null
  }

    const polygon : any = [
      [5.515, -0.09],
      [5.52, -0.1],
      [5.52, -0.12],
    ]

    const limeOptions = { color: 'lime' }
    const mapStyle = { background : "lightgray" }

    const mapData : any = ghMapData;


    return(
      <MapContainer  center={center} zoom={ zoom } scrollWheelZoom={true}   style={mapStyle}>
        <LayersControlComp/>

        {
          mapData.features?.map(
            (prop : {geometry : {type : string,coordinates : any },},i : number) => 
              //the polygon draws the coordinates of the map
              <Polygon positions = {prop.geometry.coordinates} key = {i} >
                <ChangeView _center={[5.614818,-0.205874]}  _zoom={9}/>
              </Polygon>

            )
        }

        <Polygon pathOptions={limeOptions} positions={polygon}/>

      </MapContainer>
    )
}



export default function Map() {

    

    const [center]  = useState<LatLngExpression>([7.955012, -1.0318194999999832]) //initial center position 
    const [zoom ] = useState<number> (7) //map zoom

    const renderMap = useMemo(() => <MapComp zoom={zoom} center= {center} />,[zoom,center])

    return (
            <div className = {`map_cont`} >
                {renderMap}
            </div>
        )
};