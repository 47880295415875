import React, { useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CycleWelcome from '../../components/cycle_welcome';
import { Loading, Loading2 } from '../../components/loading';
import { BsFunnel } from "react-icons/bs";
import { MdArrowDropDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchCycles } from '../../redux/actions/cycles';
import { cyclesActions } from '../../redux/slice/cycles/cyclesSlice';


const TableTile = ({ name, variety, start_date, end_date, status, id, handleClick }: any) => {

  return (
    <tr onClick={() => handleClick(id)} className='table-row'>
      <td>{name}</td>
      <td>{variety}</td>
      <td>{start_date}</td>
      <td>{end_date}</td>
      <td>{status}</td>
    </tr>
  )
}

const FarmerCycle = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const [openStates, setOpenState] = useState(false);
  const [selectedState, setSelectedState] = useState("");

  const navigate = useNavigate();

  const { cycles, isLoading, meta, failedMsg } = useSelector(
    (state: any) => state.cycles
  );

  const farmerCycles = cycles.filter((cycle: any) => cycle.is_group === 0);
  const adminId = useSelector((state: any) => state.admin.user.id);
  const companyId = useSelector((state: any) => state.admin.user.id);
  

  // if (failedMsg) console.log(failedMsg);

  useEffect(() => {
      const fetchCycleData = {
        admin_id: adminId,
        company_id: companyId,
        is_group: 1,
      } 
      dispatch(fetchCycles(fetchCycleData));
  }, [dispatch, adminId]);

  const handleClick = (id: any) => {
    dispatch(cyclesActions.CycleDetails(id));
    navigate("details");
  };

  if (isLoading) return <Loading2 />;

  // console.log(cycles);

  const handleStateSelect = (state: string) => {
    setSelectedState(state);
    setOpenState(false); 
  };

  if (!cycles) return <CycleWelcome />
  return (
    <div>
      <div className="flex justify-between">

        <div className='flex items-center' style={{ backgroundColor: 'white' }}>
          <div className='flex items-center px-24 py-3 gap-4 rounded-xl  border-gray-300 border text-gray-400'>
            <MdSearch className='h-6 w-6'/>
            <input type="text" placeholder='Search admin, email' className='text-lg w-full bg-white' />
          </div>
        </div>

        <div className="flex gap-8 items-center">
          <div className="flex items-center gap-4 border border-gray-300 rounded-xl px-6 py-3 cursor-pointer">
            <BsFunnel />
            <p>Filter</p>
          </div>
          <div className="bg-primary rounded-xl  px-6 py-3 text-white cursor-pointer" onClick={() => navigate('cycleadd')}>
            Add cycle
          </div>

        </div>

      </div>


      {/* CYCLES TABLE */}
      <div className='my-8' data-aos="fade-up" data-aos-delay="200">
        {
          !cycles
            ?
            <div style={{ width: "100%", minHeight: "400px", display: "grid", placeItems: "center" }}>
              No Cycle  yet...
            </div>
            :
            <table>
              <thead className="bg-table_head w-full">
                <tr className='rounded-2xl'>
                  {
                    ["Name", "Variety", "Start Date", "End Date"].map((tableHeadContent, i) =>
                      <th key={i} className=" uppercase">
                        {tableHeadContent}
                      </th>
                    )
                  }
                  <th className='uppercase'>
                    <div className='flex items-center cursor-pointer'
                    onClick={() => setOpenState(!openStates)}
                    >
                      <p>State</p>
                      <MdArrowDropDown />
                    </div>
                  </th>
                  {openStates && (
                        <div className="absolute top-16 right-0 mt-2 bg-white rounded-md shadow-md">
                          {["Pending", "In Progress", "Complete"].map((state) => (
                            <p
                              key={state}
                              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${selectedState === state ? "bg-gray-100" : ""
                                }`}
                              onClick={() => handleStateSelect(state)}
                            >
                              {state}
                            </p>
                          ))}
                        </div>
                    )}

                </tr>

              </thead>

            <tbody>
              {
                  farmerCycles.length === 0 && <tr className='table-row'><td>No data yet</td><td></td><td></td><td></td><td/><td/></tr>
              }
              {
                farmerCycles?.map((cycle: any,i: any)=> <TableTile key={i}  {...cycle} handleClick={handleClick} />)
              }
            </tbody>
            </table>
        }



        {
      farmerCycles &&
      <div className='back_to_top'>
          <h4>Back to top</h4>
      </div>
  }

      </div>
    </div>
  )
}

export default FarmerCycle