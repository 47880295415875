import { useState } from "react";
// import {
//   GetRealtimeWeather,
//   getCurrentLocation,
// } from "../state_manager/apis/weather.service";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineSearch } from "react-icons/md";
import { TiWeatherShower, TiWeatherSnow } from "react-icons/ti";

const Weather = () => {
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeCard, setActiveCard] = useState<number>(0);
  const [weatherData, setWeatherData] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // temporary data
  const data = [
    {
      id: 1,
      temp: 38,
      humidity: 45,
      windSpeed: 4,
      date: "Today",
      weather: "Partly Cloudy",
    },
    {
      id: 2,
      temp: 38,
      humidity: 45,
      windSpeed: 4,
      date: "Dec. 23",
      weather: "Partly Cloudy",
    },
    {
      id: 3,
      temp: 38,
      humidity: 45,
      windSpeed: 4,
      date: "Dec. 20",
      weather: "Partly Cloudy",
    },
    {
      id: 4,
      temp: 38,
      humidity: 45,
      windSpeed: 4,
      date: "Dec. 18",
      weather: "Partly Cloudy",
    },
  ];

  // const showToastMessage = (message: string) => {
  //   toast.error(message, {
  //     position: toast.POSITION.TOP_CENTER,
  //     hideProgressBar: true,
  //     autoClose: 2000,
  //   });
  // };

  // const handleSearch = async (location: string) => {
  //   try {
  //     // Set loading state to true
  //     setLoading(true);

  //     const data = await GetRealtimeWeather(location);

  //     // Update state with the retrieved data
  //     setWeatherData(data);
  //   } catch (error: any) {
  //     // Handle errors (e.g., show an error message to the user)
  //     console.error("Error fetching weather data:", error);
  //     const errorMessage =
  //       error.message === "Network Error"
  //         ? "Network error. Please check your internet connection."
  //         : `${error.response.data.error.message}`;

  //     showToastMessage(errorMessage);
  //     // setError('Error fetching weather data. Please try again.');
  //   } finally {
  //     // Set loading state back to false regardless of success or failure
  //     setLoading(false);
  //   }
  // };

  // const formattedDate = weatherData && formatDate(weatherData.location.localtime);

  // console.log(
  //   weatherData && weatherData.current && weatherData.current.condition.text
  // );

  // if (isLoading)
  //   return (
  //     <div className="flex items-center justify-center h-[360px] w-full">
  //       <div className="flex justify-center mt-16 ">
  //         <div className="load__indicator__box">
  //           <div className="load__indicator"></div>
  //         </div>
  //       </div>
  //     </div>
  //   );

  return (
    <section className="weather_content px-2" data-aos="fade-left">
      <ToastContainer />

      <div className="weather_cont flex flex-col gap-5-w-full-h-full pb-6 h-8 border-b-2 border-b-[#C4C4C4]">
        <div className="flex gap-5 items-center">
          <MdOutlineSearch className="search_icon " size={28} color="#05897C" />
          <input
            className="w-full h-full"
            type="text"
            placeholder="Ghana, Kumasi"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === "Enter"}
          />
        </div>
        <p className="font-bold text-base my-4">Weather Forecast</p>
        <p className="text-[#C4C4C4] text-base mb-4">
          5:00pm Wed. Nov. 23, 2023
        </p>
        <div className="flex items-center gap-2">
          <TiWeatherShower size={45} color="#05897C" />
          <p className="temp_c text-[#05897C] text-6xl font-extrabold">
            38
            <sup className="font-medium text-xl align-top">
              &#8451; | &#8457;
            </sup>
          </p>
        </div>
        <p className="font-bold text-base my-6">Partly Cloudy</p>
        <div className="flex gap-9 mb-5">
          <div className="flex flex-col gap-1">
            <p className="text-[#C4C4C4] text-base">Humidity</p>
            <p className="text-base font-[500]">45%</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-[#C4C4C4] text-base">Wind Speed</p>
            <p className="text-base font-[500]">4km/h</p>
          </div>
        </div>
        <div className="history-data bg-[#E5E5E5] py-10 px-4 rounded-xl -mr-7">
          <div className="container flex gap-5 w-full overflow-x-auto overflow-y-hidden hide-scrollbar ">
            {/* history data */}
            {data.map((item, index) => (
              <div
                key={item.id}
                className={`card flex flex-shrink-0 items-center flex-col gap-[2px] text-[#2A2A2A] bg-white
                  hover:bg-[#05897C] hover:text-white ${
                    activeCard === index && "bg-[#05897C]"
                  } ${
                  activeCard === index && "text-white"
                } p-2 rounded-xl delay-100 min-w-[100px]`}
                onClick={() => setActiveCard(index)}>
                <p className="text-[10px] font-[500]">{item.date}</p>
                <div className="icon">
                  <TiWeatherSnow size={34} className="" />
                </div>
                <p className="text-[#C4C4C4] text-[10px]">Humidity</p>
                <p className="text-[18px] font-[500]">{item.humidity}%</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Weather;

// function formatDate(inputDate: string) {
//   const date = new Date(inputDate);

//   const options: Intl.DateTimeFormatOptions = {
//     hour: "numeric",
//     minute: "numeric",
//     hour12: true,
//     weekday: "short",
//     month: "short",
//     day: "numeric",
//     year: "numeric",
//   };

//   const i = new Intl.DateTimeFormat("en-US", options).format(date).split(",");
//   const formattedDate = [
//     `${i[3].replace(/\s/g, "")} ${i[0]}, `,
//     `${i[1].trimStart().replace(/\s/g, ". ")}, ${i[2]}`,
//   ].join("");

//   return formattedDate;
// }
