import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FarmerProfile, FarmType } from "../../db/interfaces";
import { getFarmTypes, getCountries } from "../../functions/general";
import { axiosInstance } from "../../redux/apis/axios";
import { ADMIN_TOKEN } from "../../state_manager/constants";
import "../../styles/farmer_add.css";
import FormInput from "../../components/form_input";
import { FaPen } from "react-icons/fa";
import Avatar from "./images/profile-pic.jpg";
import { convertNewFarmerForBackend } from "../../functions/farmers";
import FarmForm from "../../components/farm_form";
import { useSelector } from "react-redux";
import { GetCountries, GetStates } from "../../redux/apis/helpers.service";
import { getCachedToken } from "../../service/common";

const showToastMessage = (message: string, type: string) => {
  const toastType = type === "success" ? toast.success : toast.error;
  toastType(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    autoClose: 2000,
  });
};

const FarmerAdd = () => {
  const { user } = useSelector((state: any) => state.admin);
  const navigation = useNavigate();

  const formDataObj: FarmerProfile = {
    farmerName: "",
    gender: "male",
    farmLocation: "",
    farmType: "",
    phoneNumber: "",
    description: "",
    houseNumber: "",
    nationalId: "",
    region: "",
    imgUrl: null,
    dob: "",
    district: "",
    state: "",
    town: "",
    farmingYears: 0,
    digitalAddress: "",
    country: 1,
    landSize: 0,
    // static agent ID value
    adminId: user?.id,
    companyId: user?.company_id,
    farms: [],
  };

  const [formData, setFormData] = useState(formDataObj);
  const [submitType, setSubmitType] = useState<string>("");
  //   const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState<string | null>(formDataObj.imgUrl);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetFarmFiled, setResetFarmFiled] = useState<boolean>(false);

// Function to handle file input change
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        imgUrl: file, // Set the file directly to the imgUrl field
      }));
      setImage(URL.createObjectURL(file)); // Display the selected image
    }
  };

  const [countries, setCountry] = useState<any>([]);
  const [states, setState] = useState<any>([]);

  useEffect(() => {
    let fetchedCountries = GetCountries();
    fetchedCountries.then((res) => setCountry(res.data.msg));
  }, []);

  useEffect(() => {
    let fetchedStates = GetStates(formData.country);
    fetchedStates.then((res) => setState(res.data.msg));
  }, [formData.country]);

  const handleSubmission = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const token = getCachedToken();
    const url = `https://backend.lbhfarm.com/admin/api/company/farmers/store`;
    const farmerDetails = convertNewFarmerForBackend(formData);

    try {
      const response = await axiosInstance.post(url, farmerDetails, {
        headers: { Authorization: `Bearer ${token?.token}` },
      });

      setIsLoading(false);

      if (response.data.code !== 200) {
        const message = response.data.msg;
        showToastMessage(message, "error");
        return;
      }

      showToastMessage(response.data.msg, "success");
      // Reset form data
      setFormData(formDataObj);
      setResetFarmFiled(true);
      setImage("");
      setTimeout(() => {
        setResetFarmFiled(false);
      }, 1000);

      // Add a delay of 1 second (1000 milliseconds) before navigating
      if (submitType === "save") {
        setTimeout(() => {
          navigation(-1);
        }, 1000);
      }
    } catch (error: any) {
      // Handle error
      setIsLoading(false);
      console.error(error);
      const errorMessage =
        error.message === "Network Error"
          ? "Network error. Please check your internet connection and try again."
          : "please provide all filed with valid parameters!";

      showToastMessage(errorMessage, "error");
    }
  };


  return (
    <div className="farmer_add" data-aos="fade-left">
      <div>
        <ToastContainer />
      </div>

      <div className="close_container flex gap-5 items-center">
        <div className="close_btn" onClick={() => navigation(-1)}>
          <MdArrowBack />
        </div>
        <h1 className="title text-[28px] font-semibold text-[#2A2A2A]">
          Add Farmer
        </h1>
      </div>

      <div className="form_cont">
        <form className="form_inner" onSubmit={handleSubmission}>
          <div className="Profile-pic flex justify-center">
            <div className="farmer_add_img_cont bg-[#37847E] w-[150px] h-[150px] p-1 rounded-full relative">
              <div className="w-full h-full ">
                <img
                  className="w-full h-full rounded-full"
                  src={image ? image : Avatar}
                  alt="Selected or captured"
                />
              </div>
              <div className="rounded-full p-3 w-fit text-white bg-[#37847E] flex justify-center items-center absolute  bottom-[10px] right-0 text-sm" role="button">
                <label className="cursor-pointer" htmlFor="filePicker">
                  {<FaPen />}
                </label>
                <input
                  id="filePicker"
                  className="hidden"
                  type={"file"}
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>

          <div className="formContainer my-8 flex justify-center items-center">
            <div className="inner-container w-[95%] sm:w-[680px]">
              <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
                <FormInput
                  name="farmerName"
                  title="Name"
                  type="text"
                  setValue={setFormData}
                  data={formData}
                  placeholder="Name"
                />
                <FormInput
                  title="Date of Birth (yyyy-mm-dd)"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="text"
                  name="dob"
                  setValue={setFormData}
                  data={formData}
                  placeholder="YYYY-MM-DD"
                />
              </div>
              <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
                <div className="flex gap-5 w-full sm:w-1/2">
                  <FormInput
                    title="Gender"
                    name="gender"
                    selectValues={["Male", "Female"]}
                    isSelect
                    setValue={setFormData}
                    data={formData}
                  />
                  <FormInput
                    title="Town"
                    type="text"
                    name="town"
                    setValue={setFormData}
                    data={formData}
                  />
                </div>
                <FormInput
                  title="Phone Number"
                  name="phoneNumber"
                  type="number"
                  setValue={setFormData}
                  data={formData}
                  placeholder="+234 8175543463"
                />
              </div>
              <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
                <FormInput
                  title="Digital Address"
                  name="digitalAddress"
                  setValue={setFormData}
                  data={formData}
                  placeholder="Digital Address"
                />
                <FormInput
                  title="Country"
                  name="country"
                  isSelectObj
                  selectValuesObj={countries}
                  setValue={setFormData}
                  data={formData}
                />
              </div>

              <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
                <FormInput
                  title="National ID Number"
                  name="nationalId"
                  setValue={setFormData}
                  data={formData}
                  placeholder="ID Number"
                />
                <FormInput
                  title="State"
                  name="state"
                  isSelectObj
                  selectValuesObj={states}
                  setValue={setFormData}
                  data={formData}
                />
              </div>
              <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
                <FormInput
                  title="Land Size(acre/s)"
                  name="landSize"
                  type="number"
                  setValue={setFormData}
                  data={formData}
                />
                <FormInput
                  title="How long have you been a farmer"
                  type="number"
                  name="farmingYears"
                  setValue={setFormData}
                  data={formData}
                />
              </div>
              <div className=" flex flex-col sm:flex-row  sm:gap-10 w-full">
                <FormInput
                  title="House Number"
                  name="houseNumber"
                  setValue={setFormData}
                  data={formData}
                />
                <FormInput
                  title="District"
                  type="text"
                  name="district"
                  setValue={setFormData}
                  data={formData}
                  placeholder="District"
                />
              </div>

              <FarmForm
                setValue={setFormData}
                resetData={resetFarmFiled}
                states={states}
              />

              <div className="sm:flex gap-5 mt-3">
                <div className="form_submit_cont">
                  <button
                    className="btn"
                    type="submit"
                    onClick={() => {
                      setSubmitType("save");
                      // handleSubmission(e);
                    }}>
                    {isLoading && submitType === "save" ? "Saving..." : "Save"}
                  </button>
                </div>
                <div className="form_submit_cont">
                  <button
                    className="btn"
                    type="submit"
                    onClick={() => {
                      setSubmitType("add_another");
                      // handleSubmission(e);
                    }}>
                    {isLoading && submitType === "add_another"
                      ? "Saving..."
                      : "Save & Add Another"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FarmerAdd;
