import React from "react";
import { Avatar } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { clientsActions } from "../../../redux/slice/client/clientsSlice";
import { useSelector, useDispatch } from "react-redux";

const ListAdmins = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { admins } = useSelector((state: any) => state.clients.clientDetails);

  const handleAdminDetails = (code: any) => {
    dispatch(clientsActions.ClientDetails(code));
    navigate("admin");
  };

  return (
    // <div>List admins</div>
    <div className="list_farmers_cont">
      <div className="h-[50px] mb-[20px] border-b border-b-[#C4C4C4] w-[100%]">
        <h1 className="text-[18px] text-center font-bold w-[100%]">
          Administrators
        </h1>
      </div>
      {!admins ? (
        <div
          style={{
            width: "100%",
            minHeight: "400px",
            display: "grid",
            placeItems: "center",
          }}
        >
          No Clients yet...
        </div>
      ) : (
        <table>
          <thead className="table_head">
            <tr className="table-row">
              {["LOGO", "NAME", "PHONE", "EMAIL"].map((tableHeadContent, i) => (
                <th key={tableHeadContent} className="table_head_content">
                  {tableHeadContent}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {admins && admins.length > 0 ? (
              admins.map((admin: any) => (
                <tr className="table-row">
                  <td onClick={() => handleAdminDetails(admin.id)}>
                    <Avatar
                      src={admin.image}
                      alt={admin.name}
                      className="t_img"
                    />
                  </td>
                  <td>{admin.name}</td>
                  <td>{admin.phone}</td>
                  <td>{admin.email}</td>
                </tr>
              ))
            ) : (
              <tr className="table-row">
                <td colSpan={5}>No data yet</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ListAdmins;
