import { createSlice } from "@reduxjs/toolkit";
import { STORE_ACTION_TYPES } from "../../types";

const initialState: any = {
  isLoading: false,
  failedMsg: "",
  fieldAgents: null,
  viewFieldAgentData: {},
  meta: {},
  fieldAgentDetails: {},
  fieldAgentDetail: {},
};

const fieldAgentsSlice: any = createSlice({
  name: "fieldAgents",
  initialState,
  reducers: {
    fieldAgentDetails: (state, action: any) => {
      const code = action.payload;
      const fieldAgentDetail = state.fieldAgentDetail[code];
      state.fieldAgentDetails = fieldAgentDetail;
      return;
    },
    [STORE_ACTION_TYPES.FIELD_AGENTS.REQUEST]: (state) => {
      state.isLoading = true;
    },
    [STORE_ACTION_TYPES.FIELD_AGENTS.RESPONSE]: (state, action) => {
      const {
        data,
        current_page,
        first_page_url,
        last_page_url,
        next_page_url,
        path,
        total,
        to,
        links,
      } = action.payload;
      state.isLoading = false;
      state.fieldAgents = data;
      state.meta = {
        current_page,
        first_page_url,
        last_page_url,
        next_page_url,
        path,
        total,
        to,
        links,
      };

      state.fieldAgentDetail = data.reduce(
        (agentAcc: any, agent: any) => ({
          ...agentAcc,
          [agent.id]: {
            id: agent.id,
            name: agent.name,
            email: agent.email,
            country: agent.country,
            state: agent.state,
            phoneNumber: agent.phone,
            image: agent.image,
            gender: agent.gender,
            age: agent.age,
            isApproved: agent.is_approved,
            cardNumber: agent.card_number,
            companyCode: agent.company_code,
            companyId: agent.company_id,
            farmers_count: agent.farmers_count,
            farmers: agent.farmers,
            farmersDetails: agent.farmers.reduce(
              (farmersAcc: any, farmer: any) => ({
                ...farmersAcc,
                [farmer.id]: {
                  id: farmer.id,
                  name: farmer.name,
                  farmer_uid: farmer.farmer_uid,
                  gender: farmer.gender,
                  dob: farmer.dob,
                  phone: farmer.phone,
                  house_number: farmer.house_number,
                  digital_address: farmer.digital_address,
                  farming_years: farmer.farming_years,
                  country: farmer.country,
                  national_id_card: farmer.national_id_card,
                  state: farmer.state,
                  town: farmer.town,
                },
              }),
              {}
            ),
          },
        }),
        {}
      );
    },
    [STORE_ACTION_TYPES.FIELD_AGENTS.FAILED]: (state, action) => {
      state.isLoading = false;
      state.failedMsg = action.payload;
    },
    [STORE_ACTION_TYPES.FIELD_AGENTS.VIEW_FIELD_AGENT]: (state, action) => {
      state.isLoading = false;
      state.viewFieldAgentData = action.payload;
    },
  },
});

export const fieldAgentsActions = fieldAgentsSlice.actions;
export default fieldAgentsSlice.reducer;
