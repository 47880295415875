import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STORE_ACTION_TYPES } from "../../types";

interface FarmerDetails {
  id: string;
  name: string;
  // Add other properties here based on your actual data structure
}

interface FarmersState {
  isLoading: boolean;
  failedMsg: string;
  farmers: null | [];
  farmers_added_today: number;
  meta: {};
  farmerDetails: {}, // Index signature for farmer details
  farmerDetail: { [key: string]: FarmerDetails };
}

const initialState : FarmersState = {
  isLoading: false,
  failedMsg: "",
  farmers: null,
  farmers_added_today: 0,
  meta: {},
  farmerDetails: {},
  farmerDetail: {},
};

const farmersSlice = createSlice({
  name: "farmers",
  initialState,
  reducers: {
    FarmerDetails: (state, { payload }: any) => {
      const code = payload;
      const farmerDetail = state.farmerDetail[code];
      state.farmerDetails = farmerDetail;
      return;
    },
    [STORE_ACTION_TYPES.FARMERS.REQUEST]: (state) => {
      state.isLoading = true;
    },
    [STORE_ACTION_TYPES.FARMERS.RESPONSE]: (state, action) => {
      const { farmers_added_today, farmers } = action.payload;
      const {
        data,
        current_page,
        first_page_url,
        last_page_url,
        next_page_url,
        prev_page_url,
        path,
        total,
        to,
        links,
        from,
      } = farmers;
      state.isLoading = false;
      state.farmers = data;
      state.farmers_added_today = farmers_added_today;
      state.meta = {
        current_page,
        first_page_url,
        last_page_url,
        next_page_url,
        prev_page_url,
        path,
        total,
        to,
        links,
        from,
      };
      state.farmerDetail = data.reduce(
        (acc: any, farmer: any) => ({
          ...acc,
          [farmer.id]: {
            id: farmer.id,
            name: farmer.name,
            farmer_uid: farmer.farmer_uid,
            gender: farmer.gender,
            dob: farmer.dob,
            phone: farmer.phone,
            house_number: farmer.house_number,
            digital_address: farmer.digital_address,
            district: farmer.district,
            farming_years: farmer.farming_years,
            country: farmer.country.name,
            country_id: farmer.country.id,
            state: farmer.state.name,
            state_id: farmer.state.id,
            national_id_card: farmer.national_id_card,
            town: farmer.town,
            farms: farmer.farms,
            land_size: farmer.land_size? farmer.land_size : farmer.farms[0]?.land_size,
            image: farmer.image,
            input_records: farmer.input_records,
            service_records: farmer.service_records,
            harvest_records: farmer.harvest_records,

          }
        }),
        {}
      )
    },
    [STORE_ACTION_TYPES.FARMERS.FAILED]: (state, action) => {
      state.isLoading = false;
      state.failedMsg = action.payload;
    },

  },
});

export const farmersActions = farmersSlice.actions;
export default farmersSlice.reducer;
