import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../../service/common";
import { BACKEND_BASE_URL } from "../../state_manager/constants";

interface adminData {
  name: string;
  email: string;
  phone: string;
  country_id: number;
  state_id: number;
  is_approved: number;
  company_id: number;
  image?: string;
  password: string;
  password_confirmation: string;
  navigation: any;
}

interface getAdmin {
  admin_id: any;
  page: any;
}

export const createAdmin = createAsyncThunk<
  adminData,
  adminData,
  { dispatch: any }
>("admin/createAdmin", async (admin_data) => {
  try {
    const response = await getAxios(BACKEND_BASE_URL).post(
      "/register",
      admin_data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});