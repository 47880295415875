import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { IoChevronForward } from "react-icons/io5";
import "../../styles/field_agents.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { requestGroups } from "../../redux/actions/groups";
import { Loading2 } from "../../components/loading";
import { MdSearch } from "react-icons/md";
import { BsFunnel } from "react-icons/bs";
import { Avatar } from "@material-ui/core";

interface Group {
  id: string;
  name: string;
  image: string;
  farming_years: string;
  farm_type: number;
}

const TableTile = ({ id, name, image, farming_years, farm_type }: Group) => {
  const navigate = useNavigate();

  return (
    <tr
      className="py-4 !bg-white hover:!bg-[#E7F7F5] font-bold text-xs text-[#2A2A2A] cursor-pointer transition-all duration-150 ease-in-out"
      // onClick={() => {
      //   navigate(`${id}`);
      // }}
    >
      <td className="py-5 text-center">{id}</td>
      <td className="py-2 text-center flex justify-center">
        {image ?? <Avatar alt={name} className="t_img " />}
      </td>
      <td className="py-5 text-center">{name}</td>
      <td className="py-5 text-center">{farming_years}</td>
      <td className="py-5 text-center">{farm_type}</td>
    </tr>
  );
};

export default function GroupDetail() {
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const tableHeadContents = ["ID", "PROFILE", "NAME", "AGE", "FARM TYPE"];

  const { user } = useSelector((state: any) => state.admin);
  const dispatch = useDispatch();
  const { groups, isLoading, meta, failedMsg } = useSelector(
    (state: any) => state.groups
  );

  const { groupDetails } = useSelector((state: any) => state.groups);

  const groupInfo = groupDetails;

  useEffect(() => {
    requestGroups(dispatch, user?.id, user?.company_id).then(() =>
      setPageLoading(false)
    );
  }, []);

  if (pageLoading) return <Loading2 />;

  return (
    <main className="p-5">
      <div
        onClick={() => navigate(-1)}
        className="w-9 h-9 rounded-full bg-primary flex justify-center items-center shadow-md cursor-pointer"
      >
        <IoArrowBackSharp className="text-white  " aria-hidden="true" />
      </div>
      <div className="max-w-[62rem] mx-auto py-6">
        <section className="w-full rounded-lg shadow-md px-5 py-8">
          <div className="flex justify-between items-center">
            <h2 className="font-semibold text-2xl/9 text-[#2A2A2A]">
              Group Details{" "}
            </h2>
            <div className="flex items-center gap-5">
              <div className="flex items-center gap-3 ring-1 ring-[#C4C4C4] rounded-[10px] px-4 py-2 cursor-pointer">
                <FiTrash2 className="w-5 h-5 text-red-500" aria-hidden="true" />
                <p className="text-xl leading-7 text-[#37847E] ">Remove</p>
              </div>
              <div className="flex items-center gap-3 ring-1 ring-[#C4C4C4] rounded-[10px] px-4 py-2 cursor-pointer">
                <FiEdit2
                  className="w-5 h-5 text-[#37847E]"
                  aria-hidden="true"
                />
                <p className="text-xl leading-7 text-[#37847E] ">Edit</p>
              </div>
            </div>
          </div>
          <div className="px-10 mt-4 w-full">
            <h1 className="font-semibold text-4xl text-black">
              {groupInfo?.name}
            </h1>
            <div className="flex flex-wrap gap-x-4 gap-y-8 items-center mt-4 justify-between">
              <div className="w-2/5 border-b border-[#C4C4C4] pb-2">
                <h5 className="font-semibold text-xl text-[#2A2A2A] mb-2">
                  Location
                </h5>
                <p className="text-[#2A2A2A] text-xl">Kumasi</p>
              </div>

              <div className="w-2/5 border-b border-[#C4C4C4] pb-2">
                <h5 className="font-semibold text-xl text-[#2A2A2A] mb-2">
                  No. of Farmers
                </h5>
                <p className="text-[#2A2A2A] text-xl">
                  {groupInfo?.farmers?.length}
                </p>
              </div>

              <div className="w-2/5 border-b border-[#C4C4C4] pb-2">
                <h5 className="font-semibold text-xl text-[#2A2A2A] mb-2">
                  Country
                </h5>
                <p className="text-[#2A2A2A] text-xl">Ghana</p>
              </div>

              <div className="w-2/5 bg-primary border-b py-3 px-5 rounded-xl flex items-center justify-between gap-4">
                <div className="">
                  <h5 className="font-bold text-xl text-white mb-2">Cycle</h5>
                  <p className="text-white text-sm">
                    current group cycle is ongoing
                  </p>
                </div>
                <div className="">
                  <IoChevronForward
                    className="text-white w-6 h-6"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full rounded-lg shadow-md px-5 py-8 mt-8">
          {isLoading ? (
            <div className="table_loading_cont">
              <div className="table_loading_cont_inner">
                <div className="table_loading_indicator"></div>
              </div>
            </div>
          ) : (
            <div className="" data-aos="fade-up" data-aos-delay="200">
              <div className="flex justify-between items-center mb-5">
                <h1 className="font-semibold text-2xl text-[#2A2A2A] ">
                  Group Farmers
                </h1>
                <div className="flex items-center gap-5">
                  <div className="flex items-center bg-white">
                    <div className="flex items-center pl-5 h-12 gap-3 rounded-xl  border-gray-300 border placeholder:text-gray-400 text-black">
                      <MdSearch
                        className="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <input
                        type="search"
                        placeholder="Search admin, email"
                        className="text-lg w-full bg-white"
                      />
                    </div>
                  </div>

                  <div className="flex items-center gap-3 border border-gray-300 text-[#25272C] rounded-xl px-6 h-12 py-1 cursor-pointer">
                    <BsFunnel />
                    <p>Filter</p>
                  </div>
                  <div
                    className="bg-primary text-xl ring-1 ring-[#C4C4C4] flex items-center gap-2 rounded-xl  px-6 h-12 text-white cursor-pointer"
                    onClick={() => {}}
                  >
                    <span className="">+</span>
                    <span className="">Add farmer</span>
                  </div>
                </div>
              </div>

              <table className="">
                <thead className="table_head ">
                  <tr className="">
                    {tableHeadContents.map((tableHeadContent, i) => (
                      <th key={i} className="text-xs text-[#2A2A2A] font-bold">
                        {tableHeadContent}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {groupInfo.farmers?.map((farmer: any, i: number) => (
                    <TableTile key={i} {...farmer} />
                  ))}

                  {groupInfo.farmers?.length === 0 && (
                    <tr>
                      <td>No Records</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div
                className="back_to_top"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <h4 className="select-none cursor-pointer  hover:text-black transition-all duration-150 ease-in-out">
                  Back to top
                </h4>
              </div>
            </div>
          )}
        </section>
      </div>
    </main>
  );
}
